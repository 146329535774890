import { FC, useState } from "react";
import styles from "./style.module.scss";
import classnames from "classnames";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

interface Props {
  onChange: (dateTimeValue: number) => void;
  value: number;
  showClose?: boolean;
  disabled?: boolean;
}

const CalendarTextBox: FC<Props> = (props) => {
  const value = props.value !== 0 ? new Date(props.value) : null;
  const [isClickedCalendarIcon, setIsClickedCalendarIcon] =
    useState<boolean>(false);

  const onclickCalendarIcon = () => {
    setIsClickedCalendarIcon((prev) => !prev);
  };

  return (
    <div className={styles.content}>
      <span
        className={classnames("material-symbols-rounded", styles.calendarIcon)}
        onClick={onclickCalendarIcon}
      >
        calendar_month
      </span>
      <DateTimePicker
        className={styles.calendar}
        disabled={props.disabled}
        maxDetail={"minute"}
        value={value}
        onChange={(dateTimeValue) => {
          if (!dateTimeValue) {
            props.onChange(0);
            return;
          }
          const data = new Date(dateTimeValue).toJSON();
          props.onChange(Date.parse(data));
        }}
        onCalendarClose={() => {
          setIsClickedCalendarIcon(false);
        }}
        isCalendarOpen={isClickedCalendarIcon}
        calendarIcon={null}
        disableClock={true}
        clearIcon={
          <button
            className={classnames(styles.clearButton, {
              [styles.hide]: !props.showClose,
            })}
          >
            <div
              className={classnames("material-symbols-rounded", styles.icon)}
            >
              close
            </div>
          </button>
        }
      />
    </div>
  );
};
export default CalendarTextBox;
