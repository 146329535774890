import { ChangeEvent, useState } from "react";
import classnames from "classnames";
import styles from "./style.module.scss";

interface Props {
  id?: string;
  maxLength?: number;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  type?: string;
  required?: boolean;
  placeholder?: string;
  value?: string;
}

const InputText: React.FunctionComponent<Props> = (props) => {
  const [textCount, setTextCount] = useState<number>(0);
  const [isError, setError] = useState<boolean>(false);
  const onChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextCount(e.currentTarget.value.length);
    setError(e.currentTarget.value.length === 0);
    props.onChange && props.onChange(e);
  };
  const max = props.maxLength ? props.maxLength : 40;
  return (
    <>
      <div className={styles.errorInput}>
        <div
          className={classnames(
            styles.inputTextArea,
            isError && props.required && styles.error
          )}
        >
          <textarea
            id={props.id}
            className={classnames(
              styles.TextArea,
              props.type === "title" && styles.title,
              props.type === "description" && styles.description
            )}
            maxLength={max}
            onChange={(e) => {
              onChangeHandler(e);
            }}
            value={props.value}
            placeholder={props.placeholder}
          />
          <div
            className={classnames(
              styles.countBox,
              textCount > 0 && styles.normal
            )}
          >
            <span id="current">{textCount}</span>
            <span id="maximum">/{max}</span>
          </div>
        </div>
        {isError && props.required && (
          <div className={styles.errorMessage}>※入力必須項目です</div>
        )}
      </div>
    </>
  );
};

export default InputText;
