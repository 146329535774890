import styles from "./style.module.scss";
import logoImage from "src/assets/images/logo/logo.svg";
import logoImage2 from "src/assets/images/logo/logo2.svg";
import { useNavigate } from "react-router-dom";

const Header: React.FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <div
      className={styles.headerContainer}
      onClick={() => {
        navigate("/");
      }}
    >
      <img className={styles.logo} src={logoImage} alt="" />
      <img className={styles.logo2} src={logoImage2} alt="" />
    </div>
  );
};

export default Header;
