import styles from './style.module.scss';

interface Props {
  imageURL: string
}

const RoomBackGround: React.FunctionComponent<Props> = (props) => {
  return (
    <div className={styles.roomBackGroundContainer}>
      <div className={styles.backGroundShadow} />
      <img className={styles.backGroundImage} src={props.imageURL} alt="" />
    </div>
  );
}

export default RoomBackGround;
