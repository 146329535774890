import { Route, Routes } from "react-router-dom";
import CommonContent from "./CommonContent";
import styles from "./style.module.scss";

const ApexContent: React.FunctionComponent = () => {
  return (
    <div className={styles.apexContentContainer}>
      <Routes>
        <Route path="/" element={<CommonContent type={"battleRoyale"} />} />
        <Route
          path="/battleRoyale"
          element={<CommonContent type={"battleRoyale"} />}
        />
        <Route path="/gunGame" element={<CommonContent type={"gunGame"} />} />
        <Route path="/control" element={<CommonContent type={"control"} />} />
        <Route
          path="/teamDeathMatch"
          element={<CommonContent type={"teamDeathMatch"} />}
        />
        <Route path="/duo" element={<CommonContent type={"duo"} />} />
      </Routes>
    </div>
  );
};

export default ApexContent;
