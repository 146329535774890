import styles from './style.module.scss';
import noRooms from 'src/assets/images/icon/noRooms.svg';

const NoRooms: React.FunctionComponent = () => {
  return (
    <div className={styles.noRoomsContainer}>
      <img className={styles.noRoomsIcon} src={noRooms} alt='' />
      <div className={styles.noRoomsDescription}>まだルームがありません</div>
      <div className={styles.noRoomsDescription}>
        ルームを作って仲間を集めましょう！
      </div>
    </div>
  );
};

export default NoRooms;
