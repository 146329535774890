import classnames from "classnames";
import styles from "./style.module.scss";
import { RoomInfo } from "src/utils/types/roomTypes";
import { useApexUser } from "src/hooks/useApexUser";
import { covertDateString, isEmpty } from "src/utils/tools/common";
import selectBackGround from "src/assets/images/icon/ellipse25.svg";
import selectedBackGround from "src/assets/images/icon/ellipse25Selected.svg";
import { useApexRoom } from "src/hooks/useApexRoom";
import { useEffect, useRef } from "react";

interface Props {
  roomInfo: RoomInfo;
  isShow: boolean;
  onClose: () => void;
}

const PlayerScheduleModal: React.FunctionComponent<Props> = (props) => {
  const uuid = useApexUser((state) => state.uuid);
  const roomID = props.roomInfo.id;
  const eventDateTime = props.roomInfo.eventDateTime;
  const scheduleInfo = props.roomInfo.scheduleDateTime;
  const updateSchedule = useApexRoom((state) => state.updateSchedule);
  const isScheduleConfirmed = !isEmpty(eventDateTime);
  const modalRef = useRef<HTMLDivElement>(null);
  const eventDateString = covertDateString(eventDateTime);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const eventNode = event.target as Node;
      if (modalRef.current && !modalRef.current.contains(eventNode)) {
        props.onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props]);

  return props.isShow ? (
    <div className={styles.modalWrapper}>
      <div className={styles.modalMask} />
      <div className={styles.scheduleContainer} ref={modalRef}>
        <span
          className={classnames(
            "material-symbols-rounded",
            styles.closeButton
          )}
          onClick={() => props.onClose()}
        >
          close
        </span>
        <div className={styles.header}>開催スケジュール</div>
        {props.roomInfo.publicFlag ? (
          <div className={styles.subHeader}>
            このルームのゲーム開催日は以下の日程となります
          </div>
        ) : (
          <div className={styles.subHeader}>
            {isScheduleConfirmed
              ? "ルームオーナーが開催日を決定しました！このルームのゲーム開催日は以下の日程となります"
              : "以下の候補日から参加可能な日程をご回答ください ※最も参加者の多い日程など考慮の上、ルームオーナーが開催日を決定します"}
          </div>
        )}

        <div className={styles.contents}>
          {props.roomInfo.publicFlag && (
            <div
              className={classnames(
                styles.publicSchedule,
                styles.confirmedSchedule
              )}
            >
              <div className={classnames(styles.date, styles.confirmedDate)}>
                {eventDateString}
              </div>
            </div>
          )}
          {!props.roomInfo.publicFlag &&
            scheduleInfo.map((info, key) => {
              const dateString = covertDateString(info.eventDateTime);
              const isYes = info.yes.includes(uuid);
              const isOr = info.or.includes(uuid);
              const isNo = info.no.includes(uuid);
              const isConfirmed = info.eventDateTime === eventDateTime;

              if (isScheduleConfirmed) {
                return (
                  <div
                    key={key}
                    className={classnames(styles.schedule, {
                      [styles.confirmedSchedule]: isConfirmed,
                      [styles.notConfirmedSchedule]: !isConfirmed,
                    })}
                  >
                    <div
                      className={classnames(
                        styles.date,
                        isConfirmed && styles.confirmedDate
                      )}
                    >
                      {dateString}
                    </div>
                    <div className={styles.selectBlock}>
                      <div
                        className={classnames(
                          styles.block,
                          styles.confirmedBlock
                        )}
                      >
                        <span
                          className={classnames(styles.blockIcon, {
                            [styles.notConfirmed]: !isConfirmed,
                          })}
                        >
                          ●
                        </span>
                      </div>
                      <div
                        className={classnames(
                          styles.number,
                          isConfirmed && styles.confirmedNumber
                        )}
                      >
                        {info.yes.length}人
                      </div>
                    </div>
                    <div className={styles.selectBlock}>
                      <div
                        className={classnames(
                          styles.block,
                          styles.confirmedBlock
                        )}
                      >
                        <span
                          className={classnames(styles.blockIcon, {
                            [styles.notConfirmed]: !isConfirmed,
                          })}
                        >
                          ▲
                        </span>
                      </div>
                      <div
                        className={classnames(
                          styles.number,
                          isConfirmed && styles.confirmedNumber
                        )}
                      >
                        {info.or.length}人
                      </div>
                    </div>
                    <div className={styles.selectBlock}>
                      <div
                        className={classnames(
                          styles.block,
                          styles.confirmedBlock
                        )}
                      >
                        <span
                          className={classnames(styles.blockIcon, {
                            [styles.notConfirmed]: !isConfirmed,
                          })}
                        >
                          ×
                        </span>
                      </div>
                      <div
                        className={classnames(
                          styles.number,
                          isConfirmed && styles.confirmedNumber
                        )}
                      >
                        {info.no.length}人
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div key={key} className={styles.schedule}>
                    <div className={styles.date}>{dateString}</div>
                    <div className={styles.selectBlock}>
                      <div
                        className={styles.block}
                        onClick={() => {
                          !isScheduleConfirmed &&
                            updateSchedule(uuid, roomID, [
                              {
                                eventDateTime: info.eventDateTime,
                                yes: [uuid],
                                or: [],
                                no: [],
                              },
                            ]);
                        }}
                      >
                        <img
                          className={classnames(
                            styles.blockBackGround,
                            isScheduleConfirmed && styles.scheduleConfirmed
                          )}
                          src={isYes ? selectedBackGround : selectBackGround}
                          alt=""
                        />
                        <span
                          className={classnames(
                            styles.blockIcon,
                            isYes && styles.selected
                          )}
                        >
                          ●
                        </span>
                      </div>
                      <div className={styles.number}>{info.yes.length}人</div>
                    </div>
                    <div className={styles.selectBlock}>
                      <div
                        className={styles.block}
                        onClick={() => {
                          updateSchedule(uuid, roomID, [
                            {
                              eventDateTime: info.eventDateTime,
                              yes: [],
                              or: [uuid],
                              no: [],
                            },
                          ]);
                        }}
                      >
                        <img
                          className={classnames(
                            styles.blockBackGround,
                            isScheduleConfirmed && styles.scheduleConfirmed
                          )}
                          src={isOr ? selectedBackGround : selectBackGround}
                          alt=""
                        />
                        <span
                          className={classnames(
                            styles.blockIcon,
                            isOr && styles.selected
                          )}
                        >
                          ▲
                        </span>
                      </div>
                      <div className={styles.number}>{info.or.length}人</div>
                    </div>
                    <div className={styles.selectBlock}>
                      <div
                        className={styles.block}
                        onClick={() => {
                          updateSchedule(uuid, roomID, [
                            {
                              eventDateTime: info.eventDateTime,
                              yes: [],
                              or: [],
                              no: [uuid],
                            },
                          ]);
                        }}
                      >
                        <img
                          className={classnames(
                            styles.blockBackGround,
                            isScheduleConfirmed && styles.scheduleConfirmed
                          )}
                          src={isNo ? selectedBackGround : selectBackGround}
                          alt=""
                        />
                        <span
                          className={classnames(
                            styles.blockIcon,
                            isNo && styles.selected
                          )}
                        >
                          ×
                        </span>
                      </div>
                      <div
                        className={classnames(
                          styles.number,
                          isConfirmed && styles.confirmedNumber
                        )}
                      >
                        {info.no.length}人
                      </div>
                    </div>
                  </div>
                );
              }
            })}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default PlayerScheduleModal;
