import { useNavigate } from "react-router-dom";
import styles from "./style.module.scss";
import arrowsLeft from "src/assets/images/icon/arrowsLeft.svg";
import logoImage from "src/assets/images/logo/logo.svg";
import logoImage2 from "src/assets/images/logo/logo2.svg";

interface HeaderProps {
  menu: string;
}

const InfoHeader: React.FunctionComponent<HeaderProps> = ({ menu }) => {
  const navigate = useNavigate();
  const handleClickGoBack = () => {
    navigate(-1);
  };
  return (
    <div className={styles.InfoHeaderWrapper}>
      <div
        className={styles.logoContainer}
        onClick={() => {
          navigate("/");
        }}
      >
        <img className={styles.logo} src={logoImage} alt="" />
        <img className={styles.logo2} src={logoImage2} alt="" />
      </div>
      <div className={styles.headerContainer}>
        <div className={styles.backButtonWrapper}>
          <div onClick={handleClickGoBack} className={styles.link}>
            <img
              className={styles.arrowsLeft}
              src={arrowsLeft}
              alt="トップページへ移動"
            />
            <div className={styles.moveToBackText}>戻る</div>
          </div>
        </div>
        <div className={styles.header}>
          <div className={styles.title}>INFORMATION</div>
          <div className={styles.subTitle}>{menu}</div>
        </div>
      </div>
    </div>
  );
};

export default InfoHeader;
