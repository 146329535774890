import classnames from "classnames";
import styles from "./style.module.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BasicButton from "src/components/Base/Button/BasicButton";
import { useApexRoom } from "src/hooks/useApexRoom";

interface Props {
  error: number;
}

const Error: React.FunctionComponent<Props> = (props) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [errorTitle, setErrorTitle] = useState<string>();
  const setLoadingMessage = useApexRoom((state) => state.setLoadingMessage);
  const navigate = useNavigate();

  useEffect(() => {
    setLoadingMessage("");
    switch (props.error) {
      case 404:
        setErrorTitle("404 Not found");
        setErrorMessage("お探しのページは見つかりませんでした。");
        break;
      case 500:
        setErrorTitle("問題が発生しました");
        setErrorMessage("");
        break;
    }
  }, [props.error, setLoadingMessage]);

  return (
    <div className={styles.modalWrapper}>
      <div className={styles.modalMask} />
      <div className={styles.modalContainer}>
        <div className={classnames(styles.error)}>
          <div className={classnames(styles.errorTitle)}>{errorTitle}</div>
          <div className={classnames(styles.errorMessage)}>{errorMessage}</div>
          <div>
            <BasicButton
              text={"トップに戻る"}
              isDisabled={false}
              onClick={() => {
                navigate("/");
              }}
              visual={"secondary"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error;
