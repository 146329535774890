import { GameMode } from "src/utils/types/roomTypes";
import { create } from "zustand";

interface ApexMenuState {
  gameModes: Array<GameMode>;
  selectedMode: string;
  selectGameMode: (mode: string) => void;
  setSelectedMode: (mode: string) => void;
  isOpenMenu: boolean;
  setIsOpenMenu: (isOpenMenu: boolean) => void;
  isSlideIn: boolean;
  setIsSlideIn: (isSlideIn: boolean) => void;
  closeMenu: () => void;
  openMenu: () => void;
}

const getNewGameModes = (selectMode: string, gameModes: Array<GameMode>) => {
  // 移動に必要なステップ計算
  const moveIndex =
    gameModes.findIndex((element) => element === selectMode) - 2;
  return gameModes.slice(moveIndex).concat(gameModes.slice(0, moveIndex));
};

const useApexMenu = create<ApexMenuState>()((set) => ({
  gameModes: ["gunGame", "control", "battleRoyale", "duo", "teamDeathMatch"],
  selectedMode: "BattleRoyale",
  isOpenMenu: false,
  isSlideIn: true,
  setIsSlideIn: (isSlideIn) =>
    set((state) => {
      return {
        isSlideIn,
      };
    }),
  setIsOpenMenu: (isOpenMenu) =>
    set((state) => {
      return {
        isOpenMenu,
      };
    }),
  selectGameMode: (mode) =>
    set((state) => {
      return {
        gameModes: getNewGameModes(mode, state.gameModes),
        selectedMode: mode,
      };
    }),
  setSelectedMode: (mode) =>
    set((state) => {
      return { selectedMode: mode };
    }),
  openMenu: () => {
    set((state) => {
      return {
        isSlideIn: true,
        isOpenMenu: true,
      };
    });
  },
  closeMenu: () => {
    set((state) => {
      return {
        isSlideIn: false,
      };
    });
    setTimeout(() => {
      set((state) => {
        return {
          isOpenMenu: false,
        };
      });
    }, 700);
  },
}));

export { useApexMenu };
