import { FC, ButtonHTMLAttributes } from "react";
import styles from "./style.module.scss";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size: "tiny" | "small" | "large";
}

const AddButton: FC<ButtonProps> = ({ size = "large", ...props }) => {
  return (
    <button
      className={styles[size]}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      <div className="material-symbols-rounded">add</div>
    </button>
  );
};
export default AddButton;
