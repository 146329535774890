import styles from "./style.module.scss";
import MockArticles from "src/assets/json/mockArticles.json";
import { useParams } from "react-router-dom";
import NewsHeader from "src/components/News/NewsHeader";
import classnames from "classnames";

const NewsDetail: React.FunctionComponent = () => {
  const { id } = useParams();
  const article = MockArticles.find((obj) => obj.id === Number(id));
  return (
    <div className={styles.newsDetailContentContainer}>
      <NewsHeader
        menu={"ニュース詳細"}
        backButtonStr={"ニュース一覧へ"}
        alt="ニュース一覧に戻る"
      />
      {id && article ? (
        <div className={styles.newsDetailContentWrapper}>
          <div className={styles.newsDetailContent}>
            <div className={styles.articleWrapper}>
              <div className={styles.typeIcon}>
                <span>{article.type.toUpperCase()}</span>
              </div>
              <div className={styles.newsDate}>{article.date}</div>
            </div>
            <div className={styles.newsTitle}>{article.title}</div>
            <div className={styles.imageWrapper}>
              <img
                className={styles.articleImage}
                alt="記事の画像"
                src={article.image}
              />
            </div>
            {article.contents.map((content) => {
              return (
                <>
                  <div
                    className={classnames(
                      styles["contentTitle" + content.titleLevel.toString()]
                    )}
                  >
                    {content.title}
                  </div>
                  <div className={styles.contentDetail}>{content.detail}</div>
                  {content.image && content.image !== "" && (
                    <div className={styles.contentImageWrapper}>
                      <img
                        className={styles.contentImage}
                        alt="記事詳細の画像"
                        src={content.image}
                      />
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </div>
      ) : (
        <div>{/* 存在しない記事番号の処理 */}</div>
      )}
    </div>
  );
};

export default NewsDetail;
