import classnames from "classnames";
import styles from "./style.module.scss";
import { useApexRoom } from "src/hooks/useApexRoom";
import { isEmpty } from "src/utils/tools/common";

const Loading: React.FunctionComponent = () => {
  const loadingMessage = useApexRoom((state) => state.loadingMessage);

  return !isEmpty(loadingMessage) ? (
    <div className={styles.modalWrapper}>
      <div className={styles.modalMask} />
      <div className={styles.modalContainer}>
        <span
          className={classnames("material-symbols-rounded", styles.loading)}
        >
          autorenew
        </span>
        <span className={styles.description}>{loadingMessage}</span>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Loading;
