import { Link } from "react-router-dom";
import styles from "./style.module.scss";
import LanguageSelect from "../Base/Button/LanguageSelect";

const Languages = [
  { value: "JAPANESE", label: "JAPANESE" },
  { value: "ENGLISH", label: "ENGLISH" },
  { value: "CHINESE", label: "CHINESE" },
];

const Footer: React.FunctionComponent = () => {
  return (
    <div className={styles.footerContainer}>
      {/* <LanguageSelect items={Languages} /> */}
      <div className={styles.footerMenuWrapper}>
        <div className={styles.footerMenuGroup}>
          <Link to="https://www.fenrir-inc.com/jp/" className={styles.linkMenu}>
            運営会社
          </Link>
          <Link to="/information/privacyPolicy" className={styles.linkMenu}>
            プライバシーポリシー
          </Link>
          <Link to="/information/terms" className={styles.linkMenu}>
            利用規約
          </Link>
          <Link to="https://www.fenrir-inc.com/jp/inquiry/oem-form/" className={styles.linkMenu}>
            お問い合わせ
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
