import { useState } from "react";
import classnames from "classnames";
import styles from "./style.module.scss";
import { isEmpty } from "src/utils/tools/common";

interface Props {
  isRequired?: boolean;
  value: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  className?: string;
  min?: number;
  max?: number;
}

const NumberBoxSmall: React.FunctionComponent<Props> = (props) => {
  const [isError, setError] = useState<boolean>(false);
  const [number, setNumber] = useState<number>(props.value);
  const onBlurHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.isRequired && e.target.value.length === 0) {
      setError(true);
      return;
    }

    if (props.min && isEmpty(e.currentTarget.value)) {
      e.currentTarget.value = props.min.toString();
    }

    if (props.min && parseInt(e.currentTarget.value) < props.min) {
      e.currentTarget.value = props.min.toString();
    }

    if (props.max && parseInt(e.currentTarget.value) > props.max) {
      e.currentTarget.value = props.max.toString();
    }

    setNumber(parseInt(e.currentTarget.value));
    props.onChange && props.onChange(e);
  };
  const inputText = (
    <div className={classnames(styles.inputContainer)}>
      <input
        className={classnames(
          styles.inputText,
          isError && styles.error,
          props.className
        )}
        value={number}
        onChange={(e) => {
          setNumber(parseInt(e.currentTarget.value));
        }}
        type={"number"}
        onBlur={onBlurHandler}
      />
      <span className={classnames(styles.description)}>人</span>
      {isError && <div className={styles.errorMessage}>※入力必須項目です</div>}
    </div>
  );
  return inputText;
};

export default NumberBoxSmall;
