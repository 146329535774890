import {
  ChangeEvent,
  MouseEvent,
  FC,
} from "react";
import styles from "./style.module.scss";
import classnames from "classnames";

interface Props {
  placeholder: string;
  onClick: (
    e: MouseEvent<HTMLButtonElement>
  ) => void;
  onChange: (
    e: ChangeEvent<HTMLInputElement>
  ) => void;
  value: string;
  hideClose: boolean;
  max?: number;
}

const KeywordTextBox: FC<Props> = (props) => {
  return (
    <div className={styles.content}>
      <input
        type="text"
        className={styles.keywordTextBox}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        maxLength={props.max}
      />
      {!props.hideClose && (
        <button className={styles.keywordButton} onClick={props.onClick}>
          <div className={classnames("material-symbols-rounded", styles.icon)}>
            close
          </div>
        </button>
      )}
    </div>
  );
};
export default KeywordTextBox;
