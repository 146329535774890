import { Member, Chat } from "src/utils/types/roomTypes";
import styles from "./style.module.scss";

interface Props {
  chat: Chat[];
  ownerName: string;
  currentUserName: string;
  otherUserArr: Member[];
}

const ChatMessage: React.FunctionComponent<Props> = ({
  chat,
  ownerName,
  currentUserName,
  otherUserArr,
}) => {
  /*
  @の半角・全角両方対応
  全角＠の場合、画面上表示では半角@に統一
  */
  const validateMension = (name: string, message: string): boolean => {
    if ("@" + name === message || "＠" + name === message) {
      return true;
    } else {
      return false;
    }
  };

  const mensionMessage = (chat: Chat, index: number) => {
    const regex = /(@\w+|[\u3000\u0020]+)/g;
    const spacingArr = chat.message.split(regex);
    return (
      <li className={styles.messageInfo} key={index}>
        <div className={styles.name}>{chat.name}</div>
        <div className={styles.message}>
          {spacingArr.map((message, index) => {
            if (validateMension(ownerName, message)) {
              return (
                <span className={styles.ownerMension} key={index}>
                  @{ownerName}
                </span>
              );
            } else if (validateMension(currentUserName, message)) {
              return (
                <span className={styles.currentUserMension} key={index}>
                  @{currentUserName}
                </span>
              );
            } else {
              const otherName = otherUserArr.find(
                (member) => "@" + member.name === message
              )?.name;
              if (otherName) {
                return (
                  <span className={styles.otherUserMension} key={index}>
                    @{otherName}
                  </span>
                );
              }
              return message;
            }
          })}
        </div>
      </li>
    );
  };
  return (
    <>
      {chat.map((chat: Chat, index: number) => {
        return mensionMessage(chat, index);
      })}
    </>
  );
};
export default ChatMessage;
