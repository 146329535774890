import { forwardRef, InputHTMLAttributes, useState } from "react";
import classnames from "classnames";
import styles from "./style.module.scss";

const CustomCodeTextBox = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement>
>(({ className, type, maxLength, ...props }, ref) => {
  const [unmasking, setUnmasking] = useState<boolean>(false);
  const [isError, setError] = useState<boolean>(false);
  const isPassword = type === "password";
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(e.target.value.length === 0);
  };
  const inputText = (
    <>
      <input
        ref={ref}
        className={classnames(
          styles.inputText,
          isError && styles.error,
          className
        )}
        onChange={(e) => {
          if (!isPassword) onChangeHandler(e);
        }}
        type={unmasking ? "text" : type}
        autoComplete={"new-password"}
        value={props.value}
        {...props}
      />
      {isError && <div className={styles.errorMessage}>※入力必須項目です</div>}
    </>
  );

  return inputText;
});

export default CustomCodeTextBox;
