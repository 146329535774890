import styles from "./style.module.scss";
import { useEffect, useRef } from "react";

interface Props {
  clickOutSide: (event: MouseEvent) => void;
  inputDevices: Array<MediaDeviceInfo>;
  outputDevices: Array<MediaDeviceInfo>;
  switchOutputDevice: (deviceId: string) => void;
  switchInputDevice: (deviceId: string) => void;
  inputSelected: string;
  outputSelected: string;
  volume: number;
  onChange: (value: number) => void;
}

const VoiceSettingsModal: React.FunctionComponent<Props> = (props) => {
  const voiceSettingsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const eventNode = event.target as Node;
      if (
        voiceSettingsRef.current &&
        !voiceSettingsRef.current.contains(eventNode) &&
        eventNode.textContent !== "settings"
      ) {
        props.clickOutSide(event);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props]);

  return (
    <div ref={voiceSettingsRef} className={styles.modalWrapper}>
      <div className={styles.micSettings}>
        <div className={styles.name}>マイク</div>
        <div className={styles.devices}>
          <select
            value={props.inputSelected}
            onChange={(event) => props.switchInputDevice(event.target.value)}
          >
            {props.inputDevices.map((device) => {
              return (
                <option key={device.deviceId} value={device.deviceId}>
                  {device.label}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className={styles.speakerSettings}>
        <div className={styles.name}>スピーカー</div>
        <div className={styles.devices}>
          <select
            value={props.outputSelected}
            onChange={(event) => props.switchOutputDevice(event.target.value)}
          >
            {props.outputDevices.map((device) => {
              return (
                <option key={device.deviceId} value={device.deviceId}>
                  {device.label}
                </option>
              );
            })}
          </select>
        </div>
        <div className={styles.slideContainer}>
          <input
            type={"range"}
            min={0}
            max={100}
            defaultValue={props.volume}
            className={styles.slider}
            id={"volumeRange"}
            onChange={(event) => {
              props.onChange(Number(event.target.value));
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default VoiceSettingsModal;
