import { create } from "zustand";

interface ApexVoiceChatState {
  localVoiceStream: MediaStream | null;
  setLocalVoiceStream: (voiceStream: MediaStream | null) => void;
  isInVoice: boolean;
  setIsInVoice: (isInVoice: boolean) => void;
  changeInVoiceStatus: (
    webSocket: WebSocket | undefined,
    roomID: string,
    uuid: string,
    inVoice: string
  ) => Promise<void>;
}

const waitForOpenSocket = async (webSocket: WebSocket | undefined) => {
  if (webSocket) {
    return new Promise<void>((resolve) => {
      if (webSocket.readyState !== webSocket.OPEN) {
        webSocket.addEventListener("open", (_) => {
          resolve();
        });
      } else {
        resolve();
      }
    });
  }
};

const changeInVoiceStatus = async (
  webSocket: WebSocket | undefined,
  roomID: string,
  uuid: string,
  inVoice: string
) => {
  await waitForOpenSocket(webSocket);
  webSocket?.send(
    JSON.stringify({
      action: "changeInVoiceStatus",
      data: {
        roomID,
        uuid,
        inVoice,
      },
    })
  );
};

const useApexVoiceChat = create<ApexVoiceChatState>()((set, get) => ({
  localVoiceStream: null,
  setLocalVoiceStream: (localVoiceStream) =>
    set((state) => {
      return { localVoiceStream };
    }),
  isInVoice: false,
  setIsInVoice: (isInVoice: boolean) => {
    return set(() => {
      return {
        isInVoice,
      };
    });
  },
  changeInVoiceStatus: (
    webSocket: WebSocket | undefined,
    roomID: string,
    uuid: string,
    inVoice: string
  ) => {
    return changeInVoiceStatus(webSocket, roomID, uuid, inVoice);
  },
}));

export { useApexVoiceChat };
