import { FC, ButtonHTMLAttributes } from "react";
import styles from "./style.module.scss";
import classnames from "classnames";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isDisabled: boolean;
  isLoading: boolean;
}

const JoinButton: FC<ButtonProps> = ({ isDisabled = true, ...props }) => {
  return (
    <button
      className={classnames(
        styles.joinButton,
        props.isLoading ? styles.isLoading : ""
      )}
      disabled={isDisabled}
      data-disabled={isDisabled}
      onClick={props.onClick}
    >
      {props.isLoading && (
        <div className={styles.container}>
          <span
            className={classnames("material-symbols-rounded", styles.rotate)}
          >
            autorenew
          </span>
          <div className={styles.content}>Loading...</div>
        </div>
      )}
      {!props.isLoading && (
        <div className={styles.content}>
          チームの
          <br />
          ボイスチャットに参加
        </div>
      )}
    </button>
  );
};
export default JoinButton;
