import geerMenu from "src/assets/images/icon/geerMenu.svg";
import bottomLine1 from "src/assets/images/icon/bottomLine1.svg";
import bottomLine2 from "src/assets/images/icon/bottomLine2.svg";
import topLine1 from "src/assets/images/icon/topLine1.svg";
import topLine2 from "src/assets/images/icon/topLine2.svg";
import { useApexMenu } from "src/hooks/useApexMenu";
import cn from "classnames";
import styles from "./style.module.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApexRoom } from "src/hooks/useApexRoom";
import { GameMode } from "src/utils/types/roomTypes";
import apexGameInfo from "src/assets/json/apexGameInfo.json";

interface Props {
  gameModes: Array<GameMode>;
  onSelect: (mode: string) => void;
}

const GeerSelector: React.FunctionComponent<Props> = (props) => {
  const [isRotate, setIsRotate] = useState(false);
  const setSearching = useApexRoom((state) => state.setSearching);
  const closeMenu = useApexMenu((state) => state.closeMenu);
  const navigate = useNavigate();

  const onSelectMenu = (gameMode: GameMode) => {
    setIsRotate(true);
    setSearching(false);
    props.onSelect(gameMode);
    navigate(apexGameInfo[gameMode]?.path);
  };

  return (
    <div className={styles.geerContainer}>
      <img
        className={cn(styles.backGroundImage, { [styles.rotate]: isRotate })}
        src={geerMenu}
        onAnimationEnd={() => setIsRotate(false)}
        alt=""
      />
      <div className={styles.selectedBackGround} />
      <div>
        <img className={styles.topLine1} src={topLine1} alt="" />
        <img className={styles.topLine2} src={topLine2} alt="" />
        <img className={styles.bottomLine1} src={bottomLine1} alt="" />
        <img className={styles.bottomLine2} src={bottomLine2} alt="" />
      </div>
      <div>
        <div
          className={styles.topItem1}
          onClick={() => {
            onSelectMenu(props.gameModes[0]);
            closeMenu();
          }}
        >
          {apexGameInfo[props.gameModes[0]].en}
        </div>
        <div
          className={styles.topItem2}
          onClick={() => {
            onSelectMenu(props.gameModes[1]);
            closeMenu();
          }}
        >
          {apexGameInfo[props.gameModes[1]].en}
        </div>
        <div
          className={styles.middleItem}
          onClick={() => {
            onSelectMenu(props.gameModes[2]);
            closeMenu();
          }}
        >
          <div className={styles.name1}>
            {apexGameInfo[props.gameModes[2]].en}
          </div>
          <div className={styles.name2}>
            {apexGameInfo[props.gameModes[2]].jp}
          </div>
        </div>
        <div
          className={styles.bottomItem1}
          onClick={() => {
            onSelectMenu(props.gameModes[3]);
            closeMenu();
          }}
        >
          {apexGameInfo[props.gameModes[3]].en}
        </div>
        <div
          className={styles.bottomItem2}
          onClick={() => {
            onSelectMenu(props.gameModes[4]);
            closeMenu();
          }}
        >
          {apexGameInfo[props.gameModes[4]].en}
        </div>
      </div>
    </div>
  );
};

export default GeerSelector;
