import { useNavigate } from "react-router-dom";
import styles from "./style.module.scss";
import arrowsLeft from "src/assets/images/icon/arrowsLeft.svg";
import logoImage from "src/assets/images/logo/logo.svg";
import logoImage2 from "src/assets/images/logo/logo2.svg";

interface HeaderProps {
  menu: "このサイトの更新情報やイベント情報" | "ニュース詳細";
  backButtonStr: "トップページへ" | "ニュース一覧へ";
  alt: "トップページに戻る" | "ニュース一覧に戻る";
}

const NewsHeader: React.FunctionComponent<HeaderProps> = ({
  menu,
  backButtonStr,
  alt,
}) => {
  const navigate = useNavigate();
  const handleClickGoTop = () => {
    navigate("/");
  };
  const handleClickGoNewsList = () => {
    navigate("/news");
  };
  return (
    <div className={styles.newsHeaderWrapper}>
      <div
        className={styles.logoContainer}
        onClick={() => {
          navigate("/");
        }}
      >
        <img className={styles.logo} src={logoImage} alt="WINNITYロゴ1" />
        <img className={styles.logo2} src={logoImage2} alt="WINNITYロゴ2" />
      </div>
      <div className={styles.headerContainer}>
        <div className={styles.backButtonWrapper}>
          <div
            onClick={
              backButtonStr === "ニュース一覧へ"
                ? handleClickGoNewsList
                : handleClickGoTop
            }
            className={styles.link}
          >
            <img className={styles.arrowsLeft} src={arrowsLeft} alt={alt} />
            <div className={styles.moveToBackText}>{backButtonStr}</div>
          </div>
        </div>
        <div className={styles.header}>
          <div className={styles.title}>NEWS</div>
          <div className={styles.subTitle}>{menu}</div>
        </div>
      </div>
    </div>
  );
};

export default NewsHeader;
