import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./components/Layout/Main";
import ApexRoom from "./components/Layout/ApexRoom";
import RouteGuard from "./providers/RouteGuard";
import LP from "./components/LP";
import Information from "./components/Information";
import News from "./components/News";
import NewsDetail from "./components/News/NewsDetail";
import Error from "./components/Modal/Error";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={"*"} element={<LP />} />
        <Route path={"/news"} element={<News />} />
        <Route path={"/news/:id"} element={<NewsDetail />} />
        <Route path={"/information/*"} element={<Information />} />
        <Route path={"/customGame/*"} element={<Main />} />
        <Route element={<RouteGuard />}>
          <Route path={"/apexRoom/*"} element={<ApexRoom />} />
        </Route>
        <Route path={"/404"} element={<Error error={404} />} />
        <Route path={"/502"} element={<Error error={500} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
