import styles from "./style.module.scss";
import { useEffect, useRef, useState } from "react";
import hark from "hark";
import classnames from "classnames";
import { MediaConnection } from "peerjs";

interface Props {
  mediaConnection: MediaConnection | undefined;
  memberUUID: string;
  memberName: string;
  headPhoneMuted: boolean;
  volume: number;
  inputSelected: string;
  outputSelected: string;
  localVoiceStream: MediaStream | null;
  isInVoice: string;
  micMuted: boolean;
}

interface SpeakingStatus {
  speaking: boolean;
  muted: boolean;
}

const RemoteAudioContent: React.FunctionComponent<Props> = (props) => {
  const remoteStreamRef = useRef<HTMLAudioElement>(null);
  const [speakingStatus, setSpeakingStatus] = useState<SpeakingStatus>();

  useEffect(() => {
    if (remoteStreamRef.current) {
      remoteStreamRef.current.muted = props.headPhoneMuted;
    }
  }, [props.headPhoneMuted]);

  useEffect(() => {
    const audioACtx = new Audio() as any;
    if (remoteStreamRef && remoteStreamRef.current?.srcObject) {
      audioACtx.srcObject = remoteStreamRef.current.srcObject;
      audioACtx.setSinkId(props.outputSelected).then(() => {
        if (remoteStreamRef.current) {
          remoteStreamRef.current.srcObject = audioACtx.srcObject;
        }
      });
    }
  }, [props.outputSelected]);

  useEffect(() => {
    if (props.localVoiceStream) {
      props.mediaConnection?.peerConnection
        ?.getSenders()[0]
        .replaceTrack(props.localVoiceStream?.getAudioTracks()[0]);
    }
  }, [
    props.inputSelected,
    props.localVoiceStream,
    props.micMuted,
    props.mediaConnection?.peerConnection,
  ]);

  useEffect(() => {
    if (remoteStreamRef.current) {
      remoteStreamRef.current.volume = props.volume;
    }
  }, [props.volume]);

  useEffect(() => {
    props.mediaConnection?.on("stream", (remoteStream) => {
      if (remoteStreamRef.current) {
        remoteStreamRef.current.srcObject = remoteStream;
        remoteStreamRef.current.play();
      }

      const remoteSpeechEvents = hark(remoteStream);

      remoteSpeechEvents.on("speaking", () => {
        setSpeakingStatus({
          muted: false,
          speaking: true,
        });
      });

      remoteSpeechEvents.on("stopped_speaking", () => {
        setSpeakingStatus({
          muted: false,
          speaking: false,
        });
      });
    });
  }, [props.mediaConnection]);

  return (
    <div className={styles.voiceChatMemberInfo}>
      <audio autoPlay ref={remoteStreamRef} className={styles.audio} />
      <span
        className={classnames("material-symbols-rounded", styles.chatting, {
          [styles.speaking]: speakingStatus?.speaking,
        })}
      >
        {speakingStatus?.muted ? "mic_off" : "mic"}
      </span>
      <span className={styles.userName}>{props.memberName}</span>
    </div>
  );
};
export default RemoteAudioContent;
