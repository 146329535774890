import styles from "./style.module.scss";
import { useEffect, useState } from "react";
import hark from "hark";
import classnames from "classnames";

interface Props {
  memberName: String;
  inputSelected: string;
  micMuted: boolean;
  localVoiceStream: MediaStream | null;
}

interface SpeakingStatus {
  speaking: boolean;
  muted: boolean;
}

const LocalAudioContent: React.FunctionComponent<Props> = (props) => {
  const [speakingStatus, setSpeakingStatus] = useState<SpeakingStatus>();

  useEffect(() => {
    if (props.localVoiceStream) {
      const selfSpeechEvents = hark(props.localVoiceStream);

      selfSpeechEvents.on("speaking", () => {
        setSpeakingStatus({
          muted: false,
          speaking: true,
        });
      });

      selfSpeechEvents.on("stopped_speaking", () => {
        setSpeakingStatus({
          muted: false,
          speaking: false,
        });
      });
    }
  }, [props.inputSelected, props.localVoiceStream]);

  return (
    <div className={styles.voiceChatMemberInfo}>
      <span
        className={classnames("material-symbols-rounded", styles.chatting, {
          [styles.speaking]: speakingStatus?.speaking,
        })}
      >
        {speakingStatus?.muted ? "mic_off" : "mic"}
      </span>
      <span className={styles.userName}>{props.memberName}</span>
    </div>
  );
};
export default LocalAudioContent;
