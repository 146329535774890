import styles from "./style.module.scss";
import apexGameInfo from "src/assets/json/apexGameInfo.json";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { GameMode } from "src/utils/types/roomTypes";
import { useMediaQuery } from "react-responsive";

interface Props {
  type: GameMode;
  map: string;
}

type Point = {
  x: number;
  y: number;
};

const MAX_SCALE = 5;
const MIN_SCALE = 1;

const ORIGIN = Object.freeze({ x: 0, y: 0 });

// adjust to device to avoid blur
const { devicePixelRatio: ratio = 1 } = window;

function diffPoints(p1: Point, p2: Point) {
  return { x: p1.x - p2.x, y: p1.y - p2.y };
}

function addPoints(p1: Point, p2: Point) {
  return { x: p1.x + p2.x, y: p1.y + p2.y };
}

function scalePoint(p1: Point, scale: number) {
  return { x: p1.x / scale, y: p1.y / scale };
}

const ZOOM_SENSITIVITY = 1000;

const ApexMap: React.FunctionComponent<Props> = (props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [context, setContext] = useState<CanvasRenderingContext2D | null>(null);
  const [scale, setScale] = useState<number>(1);
  const [offset, setOffset] = useState<Point>(ORIGIN);
  const [mousePos, setMousePos] = useState<Point>(ORIGIN);
  const [viewportTopLeft, setViewportTopLeft] = useState<Point>(ORIGIN);
  const isResetRef = useRef<boolean>(false);
  const lastMousePosRef = useRef<Point>(ORIGIN);
  const lastOffsetRef = useRef<Point>(ORIGIN);
  const isChangeMapSize = useMediaQuery({ query: "(max-width: 1466px)" });
  const WIDTH = isChangeMapSize ? 740 : 1240;
  const HEIGHT = 740;
  const middleWidth = isChangeMapSize ? 0 : 234;
  const middleHeight = isChangeMapSize ? 0 : 16;

  // update last offset
  useEffect(() => {
    lastOffsetRef.current = offset;
    if (context) {
      // clear canvas but maintain transform
      const storedTransform = context.getTransform();
      context.canvas.width = context.canvas.width;
      context.setTransform(storedTransform);

      const image = new Image();
      const maps = apexGameInfo[props.type] && apexGameInfo[props.type].maps;
      image.src = maps[props.map as keyof typeof maps]?.mapImage;

      image.addEventListener("load", () => {
        context.drawImage(image, middleWidth, middleHeight, HEIGHT, HEIGHT);
      });

      return image.removeEventListener("load", () => {
        context.drawImage(image, middleWidth, middleHeight, HEIGHT, HEIGHT);
      });
    }
  }, [
    HEIGHT,
    context,
    middleHeight,
    middleWidth,
    offset,
    props.map,
    props.type,
  ]);

  // reset
  const reset = useCallback((context: CanvasRenderingContext2D) => {
    if (context && !isResetRef.current) {
      // adjust for device pixel density
      context.canvas.width = WIDTH * ratio;
      context.canvas.height = HEIGHT * ratio;
      context.scale(ratio, ratio);
      setScale(1);

      // reset state and refs
      setContext(context);
      setOffset(ORIGIN);
      setMousePos(ORIGIN);
      setViewportTopLeft(ORIGIN);
      lastOffsetRef.current = ORIGIN;
      lastMousePosRef.current = ORIGIN;

      // this thing is so multiple resets in a row don't clear canvas
      isResetRef.current = true;
    }
  }, []);

  // functions for panning
  const mouseMove = useCallback(
    (event: MouseEvent) => {
      if (context) {
        const lastMousePos = lastMousePosRef.current;
        const currentMousePos = { x: event.pageX, y: event.pageY }; // use document so can pan off element
        lastMousePosRef.current = currentMousePos;

        const mouseDiff = diffPoints(currentMousePos, lastMousePos);
        setOffset((prevOffset) => addPoints(prevOffset, mouseDiff));
      }
    },
    [context]
  );

  const mouseUp = useCallback(() => {
    document.removeEventListener("mousemove", mouseMove);
    document.removeEventListener("mouseup", mouseUp);
  }, [mouseMove]);

  const startPan = useCallback(
    (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
      document.addEventListener("mousemove", mouseMove);
      document.addEventListener("mouseup", mouseUp);
      lastMousePosRef.current = { x: event.pageX, y: event.pageY };
    },
    [mouseMove, mouseUp]
  );

  // setup canvas and set context
  useLayoutEffect(() => {
    if (canvasRef.current) {
      // get new drawing context
      const renderCtx = canvasRef.current.getContext("2d");

      if (renderCtx) {
        reset(renderCtx);
      }
    }
  }, [reset]);

  // pan when offset or scale changes
  useLayoutEffect(() => {
    if (context && lastOffsetRef.current) {
      const offsetDiff = scalePoint(
        diffPoints(offset, lastOffsetRef.current),
        scale
      );
      context.translate(offsetDiff.x, offsetDiff.y);
      setViewportTopLeft((prevVal) => diffPoints(prevVal, offsetDiff));
      isResetRef.current = false;
    }
  }, [context, offset, scale]);

  // draw
  useLayoutEffect(() => {
    if (context) {
      // clear canvas but maintain transform
      const storedTransform = context.getTransform();
      // eslint-disable-next-line no-self-assign
      context.canvas.width = context.canvas.width;
      context.setTransform(storedTransform);

      const image = new Image();
      const maps = apexGameInfo[props.type] && apexGameInfo[props.type].maps;
      image.src = maps[props.map as keyof typeof maps]?.mapImage;

      context.drawImage(image, middleWidth, middleHeight, HEIGHT, HEIGHT);

      // image.addEventListener("load", () => {
      //   context.drawImage(
      //     image,
      //     context.canvas.width / 2 - image.width / 2,
      //     context.canvas.height / 2 - image.height / 2,
      //     1000,
      //     1000
      //   );
      // });
    }
  }, [
    context,
    scale,
    offset,
    viewportTopLeft,
    props.type,
    props.map,
    middleWidth,
    middleHeight,
  ]);

  // add event listener on canvas for mouse position
  useEffect(() => {
    const canvasElem = canvasRef.current;
    if (canvasElem === null) {
      return;
    }

    function handleUpdateMouse(event: MouseEvent) {
      event.preventDefault();
      if (canvasRef.current) {
        const viewportMousePos = { x: event.clientX, y: event.clientY };
        const topLeftCanvasPos = {
          x: canvasRef.current.offsetLeft,
          y: canvasRef.current.offsetTop,
        };
        setMousePos(diffPoints(viewportMousePos, topLeftCanvasPos));
      }
    }

    canvasElem.addEventListener("mousemove", handleUpdateMouse);
    canvasElem.addEventListener("wheel", handleUpdateMouse);
    return () => {
      canvasElem.removeEventListener("mousemove", handleUpdateMouse);
      canvasElem.removeEventListener("wheel", handleUpdateMouse);
    };
  }, []);

  // add event listener on canvas for zoom
  useEffect(() => {
    const canvasElem = canvasRef.current;
    if (canvasElem === null) {
      return;
    }

    // this is tricky. Update the viewport's "origin" such that
    // the mouse doesn't move during scale - the 'zoom point' of the mouse
    // before and after zoom is relatively the same position on the viewport
    function handleWheel(event: WheelEvent) {
      event.preventDefault();
      if (context) {
        const zoom = 1 - event.deltaY / ZOOM_SENSITIVITY;
        if (scale * zoom < MAX_SCALE && scale * zoom > MIN_SCALE) {
          const viewportTopLeftDelta = {
            x: (mousePos.x / scale) * (1 - 1 / zoom),
            y: (mousePos.y / scale) * (1 - 1 / zoom),
          };
          const newViewportTopLeft = addPoints(
            viewportTopLeft,
            viewportTopLeftDelta
          );

          context.translate(viewportTopLeft.x, viewportTopLeft.y);
          context.scale(zoom, zoom);
          context.translate(-newViewportTopLeft.x, -newViewportTopLeft.y);

          setViewportTopLeft(newViewportTopLeft);
          setScale(scale * zoom);
          isResetRef.current = false;
        }
      }
    }

    canvasElem.addEventListener("wheel", handleWheel);
    return () => canvasElem.removeEventListener("wheel", handleWheel);
  }, [context, mousePos.x, mousePos.y, viewportTopLeft, scale]);

  return (
    <canvas
      ref={canvasRef}
      onMouseDown={startPan}
      className={styles.mapCanvas}
      width={isChangeMapSize ? 740 : 1240}
      height={isChangeMapSize ? 740 : 772}
    />
  );
};

export default ApexMap;
