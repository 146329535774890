/**
 * isEmpty
 * 対象のオブジェクトが空かどうかを返す、 空判定や false な判定であれば true
 * ex. 0 => true, NaN => true, 1 => false
 *     "" => true, "a" => false
 *     true => true, true => false
 *     [] => true, [3] => false
 *     {} => true, {"1": "2"} => false
 * @param {object|string|number|undefined} target 判定対象のオブジェクト
 */
export const isEmpty = (target: unknown): boolean => {
  if (!target) return true;
  const type = typeof target;
  if (type === "boolean" || type === "number") return false;
  if (type === "object") {
    for (const _ in target) return false;
    return true;
  }
  if (type === "string") {
    const newTarget = target as string;
    return newTarget.trim() === "";
  }
  return false;
};

export const covertDateString = (dateTime: number): string => {
  const newDateTime = new Date(dateTime);
  const dateTimeString = newDateTime.toLocaleString().replaceAll("/", ".");
  return dateTimeString.slice(0, dateTimeString.length - 3);
};
