import { Route, Routes } from "react-router-dom";
import CommonRoom from "./CommonRoom";

interface Props {
  webSocket: WebSocket | undefined;
}

const Room: React.FunctionComponent<Props> = (props) => {
  return (
    <Routes>
      <Route
        path="/battleRoyale"
        element={
          <CommonRoom webSocket={props.webSocket} type={"battleRoyale"} />
        }
      />
      <Route
        path="/gunGame"
        element={<CommonRoom webSocket={props.webSocket} type={"gunGame"} />}
      />
      <Route
        path="/control"
        element={<CommonRoom webSocket={props.webSocket} type={"control"} />}
      />
      <Route
        path="/teamDeathMatch"
        element={
          <CommonRoom webSocket={props.webSocket} type={"teamDeathMatch"} />
        }
      />
      <Route
        path="/duo"
        element={<CommonRoom webSocket={props.webSocket} type={"duo"} />}
      />
    </Routes>
  );
};

export default Room;
