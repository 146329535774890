import { FC, useEffect, useState } from "react";
import styles from "./style.module.scss";

interface InputProps {
  onText?: string;
  offText?: string;
  checked?: boolean;
  onChange?: () => void;
}

const ToggleButton: FC<InputProps> = (props) => {
  const [isChecked, setChecked] = useState(props.checked);
  
  useEffect(()=>{
    setChecked(props.checked);
  }, [props.checked])

  return (
    <div className={styles.checkboxSwitch}>
      <input
        {...props}
        hidden
        type="checkbox"
        checked={isChecked}
        className={styles.inputCheckbox}
        onChange={(e) => {
          setChecked(!isChecked);
        }}
      />
      <label
        className={styles.checkboxAnimate}
        onClick={() => {
          setChecked(!isChecked);
          props.onChange && props.onChange();
        }}
      >
        <span className={styles.checkboxOff}>
          {props.offText ? props.offText : "オフ"}
        </span>
        <span className={styles.checkboxOn}>
          {props.onText ? props.onText : "オン"}
        </span>
      </label>
    </div>
  );
};
export default ToggleButton;
