import "./style.scss";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import newsIcon from "src/assets/images/icon/ellipseBlue.svg";
import eventIcon from "src/assets/images/icon/ellipseRed.svg";
//PC
import pc_mv_bg_img from "src/assets/images/2x/pc_mv_bg_image.png";
import pc_img_people from "src/assets/images/2x/pc_img_people.png";
import pc_title from "src/assets/images/2x/pc_title.png";
import pc_logo from "src/assets/images/2x/pc_logo.png";
//SP
import sp_mv_bg_image from "src/assets/images/2x/lp/sp/sp_mv_bg_image.png";
import sp_img_people from "src/assets/images/2x/lp/sp/sp_img_people.png";
import sp_title from "src/assets/images/2x/lp/sp/sp_title.png";
import sp_logo from "src/assets/images/2x/lp/sp/sp_logo.png";

import about_bg from "src/assets/images/2x/contents_bg@2x.png";
import about_img from "src/assets/images/2x/lp/about_img_01@2x.png";
import about_img_mask from "src/assets/images/2x/lp/Mask group.png";

import features_img_01 from "src/assets/images/2x/lp/feature/features_img_01.png";
import features_img_02 from "src/assets/images/2x/lp/feature/features_img_02.png";
import features_img_03 from "src/assets/images/2x/lp/feature/features_img_03.png";
import features_img_04 from "src/assets/images/2x/lp/feature/features_img_04.png";

import lp_start_bg from "src/assets/images/2x/lp/lp_start_bg.png";
import sp_start_bg from "src/assets/images/2x/lp/sp_start_bg.png";
import share_x from "src/assets/images/2x/share_x.png";
import share_line from "src/assets/images/2x/share_line.png";
import winnity_logo from "src/assets/images/2x/winnity_logo@2x.png";
import logo_x from "src/assets/images/2x/logo_x_white.png";
import MockArticles from "src/assets/json/mockArticles.json";

const LP: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const [isSpDevice, setIsSpDevice] = useState<boolean>(false);

  const handleResize = () => {
    setIsSpDevice(window.outerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleStartButton = () => {
    navigate("/customGame/battleRoyale");
  };

  const X_URL = "https://twitter.com/WINNITY_GAMES";
  const WINNITY_URL = "https://winnity.games";
  //Xの投稿のパラメータ
  //投稿内容
  const tweet_text = "[Let's Play Apex Legends With WINNITY]";
  //共有するURL又はXのURL
  const tweet_url = WINNITY_URL;
  //ツイート内に含むユーザ名（〜より）
  const screen_name = "WINNITY_GAMES";
  //共有するハッシュタグ
  const hashtag = "ApexLegends,WINNITY,カスタムゲーム";
  //ツイート後に「おすすめユーザー」として表示
  const recommend_account = "WINNITY_GAMES";

  return (
    <div className={"lpContainer"}>
      <div className={"headerContainer"}>
        <div className={"headerContent"}>
          <div className={"bgImgContent"}>
            <img
              className={
                !isSpDevice ? "headerBackgroundImg" : "headerSpBackgroundImg"
              }
              alt={"bgImage"}
              src={!isSpDevice ? pc_mv_bg_img : sp_mv_bg_image}
            />
            <div className={"gradiant"} />
            <div className={"overlayImg"} />
            <img
              className={"pcImg"}
              alt={"プレイヤーのイメージ画像"}
              src={!isSpDevice ? pc_img_people : sp_img_people}
            />
            <h1 className="titleWrapper">
              <img
                alt={
                  "チームの結束でApex Legends™をもっと熱狂的に。仲間と連携してカチをつくる、コミュニケーションスペース"
                }
                src={!isSpDevice ? pc_title : sp_title}
                className={!isSpDevice ? "pcTitle" : "spTitle"}
              />
            </h1>
            <div className={"pcLogoWrapper"}>
              <img
                className={"pcLogo"}
                alt={"WINNITY for Apex Legends™"}
                src={!isSpDevice ? pc_logo : sp_logo}
              />
            </div>
            <div className={"startButtonWrapper"}>
              <div className={"startButton"} onClick={handleStartButton}>
                WINNITYをはじめる
              </div>
              {isSpDevice && <div className={"startButtonBg"} />}
            </div>
            <div className={"scrollDown"}>
              <div className={"rectangle"} />
              <div className={"scrollText"}>SCROLL DOWN</div>
            </div>
          </div>
        </div>
      </div>
      <div className={"newsContainer"}>
        <div className={"newsContent"}>
          <div className={"head"}>
            <span className={"logo"}>NEWS</span>
            <span className={"info"}>サイトの更新情報・イベント情報</span>
          </div>
          <div className="newsPosts">
            {MockArticles.slice(-3)
              .reverse()
              .map((articles, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <div className="news">
                      <Link to={`/news/?type=${articles.type}`}>
                        <div className="type">
                          {articles.type === "news" ? (
                            <>
                              <img src={newsIcon} alt="ellipse" />
                              <span>NEWS</span>
                            </>
                          ) : (
                            <>
                              <img src={eventIcon} alt="ellipse" />
                              <span>EVENT</span>
                            </>
                          )}
                        </div>
                      </Link>
                      <div className="date">{articles.date}</div>
                      <Link to={`/news/${articles.id}`}>
                        <div className="title">{articles.title}</div>
                      </Link>
                    </div>
                  </React.Fragment>
                );
              })}
          </div>
        </div>
      </div>
      <div className={"aboutContainer"}>
        <div className={"aboutBg"}>
          <div className={"aboutBgColor"}>
            <img alt={"aboutImg"} className={"aboutBgImg"} src={about_bg} />
          </div>
        </div>
        <div className={"aboutContentWrapper"}>
          <div className={"aboutHeaderWrapper"}>
            <div className={"headerTitle"}>ABOUT</div>
            <h2 className={"headerSubTitle"}>WINNITYについて</h2>
          </div>
          <div className={"image-container"}>
            <div className={"aboutImgWrapper"}>
              <img
                alt={"aboutScreenImg"}
                className={"aboutScreenMask"}
                src={about_img_mask}
              />
              <img
                alt={"WINNITYのルーム一覧・LOBBY・MAP画面のスクリーンショット"}
                className={"aboutScreenImg"}
                src={about_img}
              />
            </div>
          </div>
          <div className={"textContainer"}>
            <h3 className={"aboutContentTitle"}>
              Apexの「集まる・参加する・連携する」がひとつで完成。
              <br />
              仲間づくりで勝率を高める。
            </h3>
            <div className="aboutContentDescriptionWrapper">
              <p className={"aboutContentDescription"}>
                Apex Legends ™
                の壮大なステージを3人1チームで攻略していくためには、的確な戦略と仲間とのコミュニケーションが勝敗の鍵を握ります。WINNITYは、オンラインゲームの「集まる・参加する・連携する」の一連のアクションをひとつの場所で完了できます。Apex
                Legends ™
                をより快適に楽しめるコミュニケーションプラットフォームです。
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={"featuresContainer"}>
        <div className={"featuresContentWrapper"}>
          <div className={"featuresHeaderWrapper"}>
            <div className={"featuresHeaderTitle"}>FEATURES</div>
            <h2 className={"featuresHeaderSubTitle"}>WINNITYの機能</h2>
          </div>
          <div className={"featuresContent"}>
            <div className={"featuresWrapper1"}>
              <div className={"contentWrapperType1"}>
                <img
                  alt={"募集中のルームイメージ"}
                  className={"featuresImg_01"}
                  src={features_img_01}
                />
                <div className={"featuresTextWrapper1"}>
                  <h3 className={"featuresTextTitle1"}>
                    自分の参加したい条件で
                    <br />
                    ルームに参加
                  </h3>
                  <p className={"featuresTextDescription1"}>
                    日時・レベル・ターゲットに合わせたカスタムゲームを一覧で表示。
                    レベルに合わせてチャレンジしたいゲームをスムーズに選択できます。
                    オーナーとしてゲームの企画も可能です。
                  </p>
                </div>
              </div>
            </div>
            <div className={"featuresWrapper2"}>
              <div className={"contentWrapperType2"}>
                <div className={"featuresTextWrapper2"}>
                  <h3 className={"featuresTextTitle2"}>
                    メンバーと自由に
                    <br />
                    コミュニケーション
                  </h3>
                  <p className={"featuresTextDescription2"}>
                    作戦ルームでチームメンバーと自由にチャットができます。
                    テキストチャットとボイスチャットを自在に切り替えてコミュニケーションが取れます。
                  </p>
                </div>
                <div className={"features2ImgWrapper"}>
                  <img
                    alt={"チャットや音声通話、チーム一覧のイメージ"}
                    className={"featuresImg_02"}
                    src={features_img_02}
                  />
                </div>
              </div>
            </div>
            <div className={"featuresWrapper3"}>
              <div className={"contentWrapperType1"}>
                <div className={"features3ImgWrapper"}>
                  <img
                    alt={"作成会議を行うマップイメージ"}
                    className={"featuresImg_03"}
                    src={features_img_03}
                  />
                </div>
                <div className={"featuresTextWrapper3"}>
                  <h3 className={"featuresTextTitle3"}>
                    マップを見ながら
                    <br />
                    作戦会議
                  </h3>
                  <p className={"featuresTextDescription3"}>
                    チーム結成後からスペース内でグループを立ち上げることができます。
                    マップを見ながら作戦会議をしたり、ゲーム終了後はすぐに分析にも使えるため、
                    次のゲームの対策を素早くまとめることができます。
                  </p>
                </div>
              </div>
            </div>
            <div className={"featuresWrapper4"}>
              <div className={"featuresWrapper4_content"}>
                <div className={"featuresTextWrapper4"}>
                  <h3 className={"featuresTextTitle4"}>スケジュールの調整</h3>
                  <p className={"featuresTextDescription4"}>
                    一緒にゲームを楽しみたいプレイヤーとのスケジュール調整やチームの
                    人数が足りない時にスペース内で募集できます。
                  </p>
                </div>
                <div className={"features4ImgWrapper"}>
                  <img
                    alt={"スケジュールの参加可否を選択するイメージ"}
                    className={"featuresImg_04"}
                    src={features_img_04}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"startContainer"}>
        <div className={"startBgWrapper"}>
          <div className={"startBg"}>
            <img
              alt={"start_img"}
              className={"startImg"}
              src={isSpDevice ? sp_start_bg : lp_start_bg}
            />
          </div>
        </div>
        <div className={"bottomStartButtonWrapper"}>
          <div className={"bottomStartButton"} onClick={handleStartButton}>
            WINNITYをはじめる
          </div>
          {isSpDevice && <div className={"bottomStartButtonBg"} />}
        </div>
      </div>
      <div className={"shareWrapper"}>
        <div className={"shareContentWrapper"}>
          <div className={"shareText"}>SHARE</div>
          <Link
            to={`https://twitter.com/intent/tweet?hashtags=${hashtag}&original_referer=https%3A%2F%2Fcdpn.io%2F&ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Eshare%7Ctwgr%5E&related=${recommend_account}&text=${tweet_text}&url=${tweet_url}&via=${screen_name}`}
            target="_blank"
          >
            <img className={"shareIcon"} alt={"share_x"} src={share_x} />
          </Link>
          <Link
            to={`https://social-plugins.line.me/lineit/share?url=${WINNITY_URL}`}
            target="_blank"
          >
            <img className={"shareIcon"} alt={"share_line"} src={share_line} />
          </Link>
        </div>
      </div>
      <div className={"footerWrapper"}>
        <img className={"footerLogo"} alt={"winnity_logo"} src={winnity_logo} />
        <div className={"footerLinkWrapper"}>
          <div className={"footerLink"}>
            <Link to="https://www.fenrir-inc.com/jp/">運営会社</Link>
          </div>
          <div className={"footerLink"}>
            <Link to="/information/privacyPolicy">プライバシーポリシー</Link>
          </div>
          <div className={"footerLink"}>
            <Link to="/information/terms">利用規約</Link>
          </div>
          <div className={"footerLink"}>
            <Link to="https://www.fenrir-inc.com/jp/inquiry/oem-form/">
              お問い合わせ
            </Link>
          </div>
          <div className={"footerLink"}>
            <a href="/presskit/WINNITY_Presskit.zip" download rel="noopener">
              プレスキット
            </a>
          </div>
        </div>
        <Link to={X_URL}>
          <img className={"logo_x"} alt={"logo_x"} src={logo_x} />
        </Link>
        <div className={"copyRightInfo"}>
          Apex Legends は米国の Electronic Arts 社 (以下 EA 社)
          の登録商標です。商標はそれぞれの所有者に帰属します。ゲーム素材の著作権は
          EA 社に帰属します。EA
          社は本サイト及びその内容を保証するものではなく、一切の責任を負いません。
        </div>
      </div>
    </div>
  );
};

export default LP;
