import styles from './style.module.scss';
import logoImage from 'src/assets/images/logo/logo.svg';

const RoomHeader: React.FunctionComponent = () => {
  return (
    <div className={styles.headerContainer}>
      <img className={styles.logo} src={logoImage} alt='' />
    </div>
  );
};

export default RoomHeader;
