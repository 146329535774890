import { Outlet } from "react-router-dom";
import JoinRoomModal from "src/components/Modal/JoinRoomModal";
import { useApexUser } from "src/hooks/useApexUser";
import { useEffect, useState } from "react";
import { useApexRoom } from "src/hooks/useApexRoom";
import { useNavigate } from "react-router-dom";
import Loading from "src/components/Modal/Loading";
import { isEmpty } from "src/utils/tools/common";
import Card from "src/components/Card";
import { RoomInfo } from "src/utils/types/roomTypes";
import styles from "./style.module.scss";
import classnames from "classnames";

const RouteGuard: React.FunctionComponent = () => {
  const query = new URLSearchParams(window.location.search);
  const roomID = query.get("roomID") || "";
  const token = useApexUser((state) => state.token);
  const auth = useApexUser((state) => state.auth);
  const checkRoomStatus = useApexRoom((state) => state.checkRoomStatus);
  const initRoomInfo = useApexRoom((state) => state.roomInfo);
  const setLoadingMessage = useApexRoom((state) => state.setLoadingMessage);
  const [isShowJoinModal, setIsShowJoinModal] = useState(false);
  const [roomStatus, setRoomStatus] = useState<RoomInfo>(initRoomInfo);
  const [isAuthed, setIsAuthed] = useState(false);
  const navigate = useNavigate();
  const loadingMessage = useApexRoom((state) => state.loadingMessage);

  useEffect(() => {
    setLoadingMessage("Loading...");
    auth(token, roomID).then((result) => {
      setIsAuthed(result);
      if (result) {
        setLoadingMessage("");
      } else {
        checkRoomStatus(roomID)
          .then((status) => {
            setRoomStatus(status);
            setLoadingMessage("");
          })
          .catch((error) => {
            switch (error.response.status) {
              case 404:
                navigate("/404");
                break;
              default:
                navigate("/500");
                break;
            }
          });
      }
    });
  }, [auth, checkRoomStatus, navigate, roomID, setLoadingMessage, token]);

  if (!isEmpty(loadingMessage)) {
    return <Loading />;
  }

  return isAuthed ? (
    <Outlet />
  ) : (
    <div className={classnames(styles.container)}>
      {isShowJoinModal ? (
        <JoinRoomModal
          isPlayer={true}
          roomID={roomID}
          publicFlag={roomStatus.publicFlag}
          onClose={() => {
            navigate("/");
          }}
          roomType={roomStatus.type}
          byURL={true}
        />
      ) : (
        <>
          <div className={classnames(styles.modalMask)} />
          <Card
            roomInfo={roomStatus}
            onClick={() => {
              setIsShowJoinModal(true);
            }}
            byURL={true}
            onClose={() => {
              navigate("/");
            }}
          />
        </>
      )}
    </div>
  );
};

export default RouteGuard;
