import { create } from "zustand";
import { Chat } from "src/utils/types/roomTypes";

interface ApexChatState {
  lobbyChat: Array<Chat>;
  setLobbyChat: (lobbyChat: Array<Chat>) => void;
  teamChat: Array<Chat>;
  setTeamChat: (teamChat: Array<Chat>) => void;
  sendLobbyMessage: (
    webSocket: WebSocket | undefined,
    roomID: string,
    name: string,
    message: string,
    uuid: string
  ) => void;
  sendTeamMessage: (
    webSocket: WebSocket | undefined,
    roomID: string,
    name: string,
    message: string,
    uuid: string,
    teamName: string
  ) => void;
  isOpenChat: boolean;
  setIsOpenChat: (isOpenChat: boolean) => void;
  isSlideIn: boolean;
  setIsSlideIn: (isSlideIn: boolean) => void;
  closeChat: () => void;
  openChat: () => void;
}

const sendLobbyMessage = async (
  webSocket: WebSocket | undefined,
  roomID: string,
  name: string,
  message: string,
  uuid: string
): Promise<void> => {
  await waitForOpenSocket(webSocket);
  webSocket?.send(
    JSON.stringify({
      action: "sendLobbyMessage",
      data: {
        roomID,
        name,
        message,
        uuid,
      },
    })
  );
};

const sendTeamMessage = async (
  webSocket: WebSocket | undefined,
  roomID: string,
  name: string,
  message: string,
  uuid: string,
  teamName: string
): Promise<void> => {
  await waitForOpenSocket(webSocket);
  webSocket?.send(
    JSON.stringify({
      action: "sendTeamMessage",
      data: {
        roomID,
        name,
        message,
        uuid,
        teamName,
      },
    })
  );
};

const waitForOpenSocket = async (webSocket: WebSocket | undefined) => {
  if (webSocket) {
    return new Promise<void>((resolve) => {
      if (webSocket.readyState !== webSocket.OPEN) {
        webSocket.addEventListener("open", (_) => {
          resolve();
        });
      } else {
        resolve();
      }
    });
  }
};

const useApexChat = create<ApexChatState>()((set) => ({
  lobbyChat: [],
  setLobbyChat: (lobbyChat: Array<Chat>) => {
    set((state) => {
      return { lobbyChat };
    });
  },
  teamChat: [],
  setTeamChat: (teamChat: Array<Chat>) => {
    set((state) => {
      return { teamChat };
    });
  },
  sendLobbyMessage: (
    webSocket: WebSocket | undefined,
    roomID: string,
    name: string,
    message: string,
    uuid: string
  ) => {
    sendLobbyMessage(webSocket, roomID, name, message, uuid);
  },
  sendTeamMessage: (
    webSocket: WebSocket | undefined,
    roomID: string,
    name: string,
    message: string,
    uuid: string,
    teamName: string
  ) => {
    sendTeamMessage(webSocket, roomID, name, message, uuid, teamName);
  },
  isOpenChat: false,
  isSlideIn: true,
  setIsSlideIn: (isSlideIn) =>
    set((state) => {
      return {
        isSlideIn,
      };
    }),
  setIsOpenChat: (isOpenChat) =>
    set((state) => {
      return {
        isOpenChat,
      };
    }),
  openChat: () => {
    set((state) => {
      return {
        isSlideIn: true,
        isOpenChat: true,
      };
    });
  },
  closeChat: () => {
    set((state) => {
      return {
        isSlideIn: false,
      };
    });
    setTimeout(() => {
      set((state) => {
        return {
          isOpenChat: false,
        };
      });
    }, 700);
  },
}));

export { useApexChat };
