import styles from "./style.module.scss";

const Terms: React.FunctionComponent = () => {
  return (
    <div className={styles.termsWrapper}>
      <div className={styles.contentWrapper}>
        <div className={styles.articleWrapper}>
          <div className={styles.content}>
            <div className={styles.articleTitle}>第1条 総則</div>
            <ol className={styles.listType1}>
              <li>
                この利用規約（以下「本規約」といいます）は、フェンリル株式会社（以下「当社」といいます）が運営し、
                お客様（以下「利用者」といいます）に提供する「WINNITY」（以下「本サービス」という）について、
                利用者又は利用を希望する者と当社の間の本サービス利用に関する基本的な事項を定めます。
              </li>
              <li>
                本規約は、本サービスの利用に関して利用者又は利用を希望する者と当社に対して適応されます。
              </li>
              <li>
                利用者は、本規約に同意のうえ、
                本規約の定めに従って自己の責任において本サービスを利用しなければなりません。
                なお、本サービスを利用した場合には、本規約すべてに同意いただいたものとして取り扱います。
              </li>
              <li>
                利用者が未成年者である場合は、親権者など法定代理人の同意を得たうえで本サービスを利用してください。
                未成年の定義は日本法に準拠して解釈するものとし、18歳未満を指します。
              </li>
              <li>
                本サービスをご利用いただく際に必要かつ適切な機器やソフトウェア、通信手段等のご用意、
                通信費等の本サービスへの接続に係る費用については、利用者ご自身でご用意下さい。
                当社は利用者のインターネットにアクセスするための準備、方法などについては一切関与いたしません。
              </li>
              <li>
                当社は本規約を自由に改定及び変更することができます。変更内容は合理的かつ妥当なものとし、予め所定の箇所に掲載してから一定期間をもって適用されるものとします。
              </li>
            </ol>
          </div>
          <div className={styles.content}>
            <div className={styles.articleTitle}>第2条 定義</div>
            <div className={styles.articleSubTitle}>
              本サービスにおいて、以下の通り定義します。
            </div>
            <ol>
              <li>本規約：この「WINNITY 利用規約」を指します。</li>
              <li>当社：フェンリル株式会社を指します。</li>
              <li>
                本サービス：当社が運営する
                WINNITY及びその関連サービスを指します。
              </li>
              <li>
                利用者：当社が提供する本サービスをご利用されるお客様を指します。
              </li>
              <li>各当事者：断りなき場合、当社及び利用者を指します。</li>
              <li>
                チャット：本サービスが提供する機能のひとつです。利用者間でテキストベースで情報をやり取りできます。
              </li>
              <li>
                ボイスチャット：本サービスが提供する機能のひとつです。利用者間で音声ベースで情報をやり取りできます。
              </li>
            </ol>
          </div>
          <div className={styles.content}>
            <div className={styles.articleTitle}>第3条 利用者の義務</div>
            <ol className={styles.listType1}>
              <li>
                利用者は、本規約、本サービスの前提となる各ゲームプラットフォーム事業者が定める規約、その他全ての関係規約に従い、本サービスを使用するものとします。
              </li>
              <li>
                利用者は、本サービスを使用するにあたり、一部の機能において、当該機能の前提となる各ゲームプラットフォーム事業者との個別契約を結ぶ又は利用許諾を受ける必要があることを予め確認する必要があります。
              </li>
              <li>
                当社は本サービスの利用者にアカウントを付与できます。利用者は、当該アカウントを善良なる管理者の注意をもって管理し、当社の同意がある場合を除き、いかなる第三者にも開示、貸与、共有、譲渡、交換してはいけません。万が一、当該アカウントが第三者により使用された場合、当社に故意又は重過失がある場合を除き、利用者は当社にこれにより当社に生じた損害を賠償していただきます。
              </li>
              <li>
                利用に関する制限
                <br />
                利用者は、本サービスの利用に関して、以下の各号該当する行為（以下「不正行為」という）を行い、又は利用者その他の第三者をして行わせてはならないものとし、不正行為を認知した場合は直ちに当社へ通知しなければなりません。
                <div className={styles.subList}>
                  <ol className={styles.listType2}>
                    <li>
                      (1)法令、裁判所の判決、決定若しくはもしくは命令、又は法令上拘束力のある行政措置に違反する行為
                    </li>
                    <li>
                      (2)違法、犯罪又は違法行為に結びつく、又はそのおそれのある若しくはそのような違法行為を嗾す行為
                    </li>
                    <li>
                      (3)輸出関連法令により輸出が規制される使用者データの保存又は転送、その他法令に抵触する行為又は法令に抵触する行為の実現のために使用する行為
                    </li>
                    <li>
                      (4)公の秩序又は善良の風俗を害するおそれのある行為（過度に暴力的な表現、露骨な性的表現、その他反社会的な内容を含み他人に不快感を与える表現を投稿、掲載、公開、送信する行為などを含みます）
                    </li>
                    <li>
                      (5)当社又は第三者の権利（著作権、商標権、特許権等の知的財産権、名誉権、プライバシー権、その他法令上又は契約上の権利を広く含みます）を侵害する行為
                    </li>
                    <li>
                      (6)当社又は第三者になりすます行為又は意図的に虚偽の情報を流布させる行為
                      <ol className={styles.listType3}>
                        <li>
                          許可を取らずに無断で撮影したスクリーンショットの共有
                        </li>
                        <li>
                          第三者を害する目的で編集・改竄したスクリーンショットの共有
                        </li>
                        <li>許可を取らずに無断で録音した音声データの共有</li>
                        <li>
                          第三者を害する目的で編集・改竄した音声データの共有
                        </li>
                      </ol>
                    </li>
                    <li>
                      (7)本サービスが予定している利用目的と異なる目的で本サービスを利用する行為
                    </li>
                    <li>(8)反社会的勢力に対する利益供与その他の協力行為</li>
                    <li>
                      (9)政治・宗教活動、マルチ商法、その他勧誘を目的とした行為
                    </li>
                    <li>
                      (10)許諾なく第三者の個人情報、登録情報、利用履歴情報などを収集、開示又は提供する行為
                    </li>
                    <li>
                      (11)ウィルス、ワーム、トロイの木馬、破損ファイル、その他の破壊的又は不正な電子データを配布する行為
                    </li>
                    <li>
                      (12)本サービス又は本サービス使用許諾の為に当社が許諾するアプリケーションその他一切の全部又は一部について、改変、無効化、妨害し、又は、これを試みる行為
                    </li>
                    <li>
                      (13)本サービス又は本サービス利用許諾の為に当社が使用するアプリケーションその他一切に関して、テストもしくはリバースエンジニアリングを行うこと、又は限界や脆弱性を探す行為
                    </li>
                    <li>
                      (14)有償、無償を問わず、使用者以外の第三者の事業遂行のために使用する等、本サービスの全部又は一部を当社が認める以外の方法で使用する行為
                    </li>
                    <li>
                      (15)本サービス又は本サービスを受ける権利の全部又は一部を第三者に対し、譲渡、貸与、再使用許諾、又は担保として許諾する行為
                    </li>
                    <li>
                      (16)死亡、人身傷害、又は環境破壊につながる可能性がある危険度の高い活動用途へ使用する行為
                    </li>
                    <li>
                      (17)当社又は第三者を誹謗中傷若しくはもしくは名誉又は信用を傷つけるような行為
                    </li>
                    <li>
                      (18)当社による本サービスの運営又は他の利用者による本サービスの利用を妨害し、これらに支障を与える行為
                    </li>
                    <li>
                      (19)その他上記に準じるものとして当社が不適切と判断した行為
                    </li>
                  </ol>
                </div>
              </li>
            </ol>
          </div>
          <div className={styles.content}>
            <div className={styles.articleTitle}>第4条 知的財産権</div>
            <ol className={styles.listType1}>
              <li>
                本サービスを構成する有形・無形の構成物（ソフトウエアプログラム、データベース、アイコン、画像、文章、マニュアル等の関連ドキュメント等を含む）及び利用者の発言（文字・音声）等の知的財産権は、当社若しくは当社に許諾した第三者に帰属します。なお、利用者は、当社若しくは当社が権利許諾する第三者に対して、当該著作物の利用に対し著作者人格権を行使しないものとします。
              </li>
              <li>
                利用者は、本規約に同意することで、本規約に定められた本サービスを利用する権利を有します。本規約に同意いただいても、本サービスに含まれるすべての知的財産権について、本規約に定める以外の何らの許諾を利用者に与えたり、譲渡したりするものではありません。
              </li>
              <li>
                利用者は、事前に当社から承諾を得ることなく、私的利用その他法律によって明示的に認められる範囲を超えて、本サービスの一部又は全部を、そのまま又は改変して、転用若しくは複製又は頒布をすることは一切できません。
              </li>
            </ol>
          </div>
          <div className={styles.content}>
            <div className={styles.articleTitle}>
              第5条 本サービスとサービスの利用許諾の停止・終了・変更
            </div>
            <ol className={styles.listType1}>
              <li>
                当社は、次の各号のひとつ又は何れかに該当する場合、予告なく本サービスの全部又は一部の使用許諾を停止・終了させることができます。
                <div className={styles.subList}>
                  <ol className={styles.listType2}>
                    <li>
                      (1)セキュリティ向上・パフォーマンス向上・監視に伴うメンテナンスを必要とすると当社が判断した場合
                    </li>
                    <li>
                      (2)天変地異その他の不可抗力又は異常事態が発生した場合
                    </li>
                    <li>
                      (3)本サービス全部又は一部の前提となる他社システムの許諾が停止若しくは終了又は制限される場合
                    </li>
                    <li>
                      (4)本サービスを許諾するために使用されるネットワーク又はサーバを混乱させる可能性のある使用がなされた場合
                    </li>
                    <li>
                      (5)許可のない第三者による本サービスへのアクセスが判明した場合
                    </li>
                    <li>
                      (6)当社が利用者に本サービスの利用を許諾できなくなった場合
                    </li>
                    <li>
                      (7)その他、当社の単独の裁量によりやむを得ないと判断した場合
                    </li>
                  </ol>
                </div>
              </li>
            </ol>
          </div>
          <div className={styles.content}>
            <div className={styles.articleTitle}>
              第6条 機密情報及び個人情報の取り扱い
            </div>
            <ol className={styles.listType1}>
              <li>
                各当事者は、相手方の機密情報及び個人情報を有する場合、厳に秘密として保持し、相手方の事前の承諾がない限りこれらの秘密保持義務を負う内部者以外の者にこれらを開示せず、また本サービスの使用又は許諾の目的以外にこれを使用しません。各当事者は、相手方の事前の承諾を得た場合であっても機密情報又は個人情報の開示を受けた者による本条の違反について責任を負うものとします。
              </li>
              <li>
                前項にかかわらず、以下の各号のいずれかに該当する情報は、機密情報に該当しません。
                <ol className={styles.listType2}>
                  <li>
                    (1)情報開示の時点で、すでに公知又は公用である情報
                  </li>
                  <li>
                    (2)情報開示後、情報の開示を受けた各当事者の責に帰すべき事由によらず、公知又は公用となった情報
                  </li>
                  <li>
                    (3)情報の開示の以前から、情報を受領した各当事者が適法に所持していた情報
                  </li>
                  <li>
                    (4)情報の開示の後、情報を受領した各当事者が、第三者により秘密保持義務を負わず適法に入手した情報
                  </li>
                </ol>
              </li>
              <li>
                本規約において「個人情報」及び「個人データ」とは、個人情報の保護に関する法律に定める情報を指します。
              </li>
              <li>
                本サービスにおいて、当社が許諾するプログラム等を通じ、クラウド運営者が管理するクラウドサーバ上に利用者が入力・保存した利用者データは、利用者からクラウド運営者に直接許諾する情報であり、当社はこれを受領・保有しないことを利用者は確認するものとします。
              </li>
              <li>
                本サービス許諾期間が終了した場合、又は相手方から要請があった場合、各当事者は、相手方の要求に従い、速やかに秘密情報及び個人情報若しくは個人データを返却、又は廃棄するものとします。
              </li>
              <li>
                当社は、利用者又は第三者より他の利用者の個人情報又は個人データの開示を要求されても、開示いたしません。例外として、捜査機関から情報開示の要請を受領した場合、関係法令に基づいて開示することが適切と判断される状況（具体的には、発信者情報開示請求などが行われ裁判所経由で捜索差押令状がある場合、捜査関係事項照会、国際捜査共助等に関する法律若しくは特定国家との刑事共助条約等の法的根拠に基づく捜査協力の要請があった場合、又は緊急避難が成立すると判断した場合を想定します）と範囲に限り、当社では捜査に必要な情報を提供する場合があります。
              </li>
            </ol>
          </div>
          <div className={styles.content}>
            <div className={styles.articleTitle}>第7条 反社会的勢力の排除</div>
            <div className={styles.articleSubTitle}>
              各当事者は、相手方に対し、次の各号について表明し保証するものとします。
            </div>
            <ol className={styles.listType1}>
              <li>
                自らが「暴力団員による不当な行為の防止等に関する法律」に定める暴力団、その関係団体若しくは関係者、警察庁が定義する準暴力団（詐欺、恐喝、暴行、傷害等を生業とする集団的・常習的に暴力行為を行う団体）、その関係団体若しくはその関係者、総会屋、社会運動標榜ゴロ、政治活動標榜ゴロ若しくはこれらに準ずるもの又はその構成員（以下「反社会的勢力」という）ではなく、また反社会勢力ではなかったこと
              </li>
              <li>
                自らの役員（取締役、監査役、執行役、業務執行社員又はこれらに準ずる者をいう）又は実質的に経営に関与し若しくはその決定を支配する者が反社会的勢力ではないこと
              </li>
              <li>
                反社会的勢力と取引・交際しておらず、また反社会的勢力を使用し若しくはこれに自らの名義を使用させていないこと
              </li>
              <li>
                自ら又は第三者を使用して、相手方に対し暴行・脅迫・偽計・威力を用いた業務妨害行為又は信用毀損行為をしないこと
              </li>
            </ol>
          </div>
          <div className={styles.content}>
            <div className={styles.articleTitle}>第8条 不保証及び損害賠償</div>
            <ol className={styles.listType1}>
              <li>
                本サービスのご利用に関し、当社は利用者に対し、システムの完全性、有用性、安定性その他一切の保証をしないものとし、利用者は当社に対し、本サービス、本サービス利用許諾のためのシステムの不具合（本サービスのホスティング環境の障害、本サービス、利用者、各当事者等を接続する回線の問題若しくは障害、又は利用者の使用端末環境に依存する若しくは環境特有の問題等を含む）、及び本サービスの終了に起因する損害について一切の責任を追及できません。
              </li>
              <li>
                当社は、利用者にあらかじめ通知することなく本サービスの内容や仕様を変更したり、提供を停止・中断・終了する場合があります。
              </li>
              <li>
                当社は、本サービスに掲載する情報について、信頼できる情報を利用者の皆様へお届けすることに努めますが、その情報の最新性、完全性、整合性、有用性、正確性その他一切の保証をしません。
              </li>
              <li>
                本サービスのご利用は、すべて利用者ご自身の責任において行っていただくようお願い致します。
              </li>
              <li>
                当社は、本サービスに含まれる情報若しくはもしくは内容を利用することに起因して利用者が被ったあらゆる損害につき、賠償する責任を一切負いません。
              </li>
              <li>
                本サービスの利用に関し、利用者と他の利用者若しくは第三者、又は利用者と通信事業者等との間で紛争が生じた場合は、利用者の責任においてこれを解決して下さい。当社は問題の解決について、責任を負いません。。
              </li>
              <li>
                前項にかかわらず、当社が本サービスに起因して利用者に対し損害賠償義務を負う場合、当社は利用者の逸失利益、間接損害、特別損害（予見の有無を問いません。）、偶発損害、結果損害については一切の責任を負いません。なお、当社の故意又は重大な過失に基づく利用者に対して負う損害賠償責任は、利用者が当社に対して支払った過去6ヶ月分の本サービスの利用にかかる料金を上限とします（無償の本サービス
                利用者に対しては、故意・過失の有無を問わず損害賠償の責任を負いません）。
              </li>
              <li>
                当社は、利用者が本規約に違反して当社に損害を与えた場合、当該利用者に対し、損害賠償（和解費用及び合理的な弁護士費用を含む）を請求できるものとします。
              </li>
            </ol>
          </div>
          <div className={styles.content}>
            <div className={styles.articleTitle}>第9条 免責</div>
            <p>
              天変地変（地震、津波、洪水、台風、火山噴火、感染症、伝染病など）、社会的事変（戦争、暴動、内乱、テロなど）、輸送機関の事故、争議行為（ストライキ、ロックアウト、ボイコットなど）、法令の改廃・制定、公権力による命令・処分、火災等の大規模災害、停電その他、各当事者の合理的支配を超える事由により、当社が本規約に基づく債務を履行することができない場合は、本サービスの全部又は一部をいつでも変更、中断、終了できるものとします。また、これにより利用者に損害が発生した場合であっても、当社は一切の責任を負わないものとします。
            </p>
          </div>
          <div className={styles.content}>
            <div className={styles.articleTitle}>第10条 本規約等の変更</div>
            <ol className={styles.listType1}>
              <li>
                当社は、法令に反しない範囲内において、当社の裁量により本規約を変更することができます。
              </li>
              <li>
                変更内容は合理的かつ妥当なものとし、予め所定の箇所に掲載してから一定期間をもって適用されるものとします。変更の内容を利用者の方に個別に通知はいたしませんので、本サービスをご利用の際には適宜最新の本規約をご確認ください。
              </li>
              <li>
                変更後の本規約は、利用者が本規約の変更後も本サービスを使い続けることにより、変更後の本規約に対する同意をしたものとみなされます。
              </li>
            </ol>
          </div>
          <div className={styles.content}>
            <div className={styles.articleTitle}>
              第12条 準拠法及び裁判管轄
            </div>
            <p>
              本規約は日本法に準拠して解釈され、また本規約に関する紛争は大阪地方裁判所又は大阪簡易裁判所を専属の第一審管轄裁判所として解決するものとします。
            </p>
          </div>
          <div className={styles.content}>
            <div className={styles.articleTitle}>第13条 協議解決</div>
            <p>
              当社及び利用者は、本規約に定めのない事項又は本規約の解釈に疑義が生じた場合、互いに信義誠実の原則に則り、協議の上速やかに解決を図ることに努めるものとします。
            </p>
          </div>
          <div className={styles.dateContent}>
            <div>附則</div>
            <div>2024年4月1日 策定</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
