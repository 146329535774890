import { HTMLInputTypeAttribute, useState } from "react";
import { Tooltip } from "react-tooltip";
import classnames from "classnames";
import styles from "./style.module.scss";

interface Props {
  showOpenUrl?: boolean;
  type?: HTMLInputTypeAttribute;
  value?: string;
}
const CopyBox: React.FunctionComponent<Props> = (props) => {
  const [unmasking, setUnmasking] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const isPassword = props.type === "password";
  const isUrl = props.type === "url";

  return (
    <div className={classnames(styles.passwordInput)}>
      <input
        className={classnames(styles.inputText)}
        type={unmasking ? "text" : props.type}
        disabled={true}
        value={props.value}
      />
      {isUrl && (
        <button
          className={styles.openNewTagButton}
          type="button"
          onClick={() => {
            window.open(props.value, "_blank");
          }}
        >
          <span
            className={classnames(
              "material-symbols-rounded",
              styles.openNewTagIcon
            )}
          >
            open_in_new
          </span>
        </button>
      )}
      <button
        className={styles.copyButton}
        type="button"
        onClick={() => {
          setShowTooltip(true);
          navigator.clipboard.writeText(props.value as string);
        }}
      >
        <span
          className={classnames("material-symbols-rounded", styles.copyIcon)}
          data-tooltip-id={"showTooltip" + props.value}
          data-tooltip-content={"コピーしました！"}
          data-tooltip-place="bottom-end"
        >
          content_copy
        </span>
      </button>
      {isPassword && (
        <button
          className={styles.maskButton}
          type="button"
          onClick={() => setUnmasking((s) => !s)}
        >
          {unmasking ? (
            <span
              className={classnames(
                "material-symbols-rounded",
                styles.maskIcon
              )}
            >
              visibility
            </span>
          ) : (
            <span
              className={classnames(
                "material-symbols-rounded",
                styles.maskIcon
              )}
            >
              visibility_off
            </span>
          )}
        </button>
      )}
      <Tooltip
        id={"showTooltip" + props.value}
        className={styles.tooltip}
        isOpen={showTooltip}
        afterShow={() => {
          setTimeout(() => {
            setShowTooltip(false);
          }, 1000);
        }}
        delayHide={1000}
        noArrow
      />
    </div>
  );
};

export default CopyBox;
