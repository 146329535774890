import { useEffect, useRef, useState } from "react";
import styles from "./style.module.scss";
import classnames from "classnames";
import apexGameInfo from "src/assets/json/apexGameInfo.json";
import ApexMap from "src/components/ApexMap";
import { GameMode, Member, UpdateRoomInfo } from "src/utils/types/roomTypes";
import { useApexRoom } from "src/hooks/useApexRoom";
import { useApexUser } from "src/hooks/useApexUser";
import { useApexMenu } from "src/hooks/useApexMenu";
import OwnerScheduleModal from "src/components/Modal/OwnerScheduleModal";
import PlayerScheduleModal from "src/components/Modal/PlayerScheduleModal";
import DeleteWarnModal from "src/components/Modal/DeleteWarnModal";
import { isEmpty } from "src/utils/tools/common";
import CustomCodeTextBox from "src/components/Base/List/CustomCodeTextBox";
import { useMediaQuery } from "react-responsive";
import Card from "src/components/Card";
import CURoomModal from "src/components/Modal/CURoomModal";
import customCodeIcon from "src/assets/images/icon/custom_code_p.svg";

interface Props {
  webSocket: WebSocket | undefined;
  type: GameMode;
}

const CommonRoom: React.FunctionComponent<Props> = (props) => {
  const query = new URLSearchParams(window.location.search);
  const roomID = query.get("roomID") || "";
  const selectedMode = useApexMenu((state) => state.selectedMode);
  const selectGameMode = useApexMenu((state) => state.selectGameMode);
  const getRoomInfo = useApexRoom((state) => state.getRoomInfo);
  const setRoomInfo = useApexRoom((state) => state.setRoomInfo);
  const userType = useApexUser((state) => state.userType);
  const roomInfo = useApexRoom((state) => state.roomInfo);
  const uuid = useApexUser((state) => state.uuid);
  const joinApexTeam = useApexRoom((state) => state.joinApexTeam);
  const leaveApexTeam = useApexRoom((state) => state.leaveApexTeam);
  const stopHeartBeat = useApexUser((state) => state.stopHeartBeat);
  const userName = useApexUser((state) => state.userName);
  const teamName = useApexUser((state) => state.teamName);
  const setTeamName = useApexUser((state) => state.setTeamName);
  const [roomMembers, setRoomMembers] = useState<Array<Member>>([]);
  const [isShowMap, setIsShowMap] = useState<boolean>(false);
  const [isShowInfo, setIsShowInfo] = useState<boolean>(false);
  const [isShowDeleteWarn, setIsShowDeleteWarn] = useState<boolean>(false);
  const [isShowCode, setIsShowCode] = useState<boolean>(false);
  const [isShowSchedule, setIsShowSchedule] = useState<boolean>(false);
  const [codeVisibility, setCodeVisibility] = useState<boolean>(false);
  const startHeartBeat = useApexUser((state) => state.startHeartBeat);
  const isInTeam = useApexUser((state) => state.isInTeam);
  const isOwner = userType === "Owner";
  const [isShowUpdateRoomModal, setIsShowUpdateRoomModal] =
    useState<boolean>(false);
  const updateApexRoom = useApexRoom((state) => state.updateRoomInfo);
  let mapName = "";
  const isChangeTagSize = useMediaQuery({ query: "(max-width: 1735px)" });
  const modalRef = useRef<HTMLDivElement>(null);

  if (!isEmpty(roomInfo.type)) {
    const maps = apexGameInfo[roomInfo.type as GameMode]?.maps;
    mapName = maps[roomInfo.map as keyof typeof maps]?.en;
  }

  const joinLeaveTeam = (isInTeamStatus: boolean, teamName: string) => {
    if (isInTeamStatus) {
      leaveApexTeam(props.webSocket, uuid, roomID, teamName);
    } else {
      joinApexTeam(props.webSocket, uuid, roomID, teamName);
    }
  };

  useEffect(() => {
    if (selectedMode !== props.type) selectGameMode(props.type);
    getRoomInfo(roomID).then((newRoomInfo) => {
      setRoomInfo(newRoomInfo);
      setRoomMembers(newRoomInfo.members);
      const isInTeamStatus = newRoomInfo.members?.find((member) => {
        return member.uuid === uuid && !isEmpty(member.teamName);
      });
      setTeamName(isInTeamStatus ? isInTeamStatus.teamName : "");
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const wsEvent = (event: MessageEvent<any>) => {
      const wsData = JSON.parse(event.data);
      switch (wsData.type) {
        case "joinApexRoom":
          const joinRoomMember = wsData.data.joinRoomMember;
          const newJoinRoomInfo = roomMembers.filter((member) => {
            if (member.uuid === joinRoomMember.uuid) {
              joinRoomMember.name = member.name;
              if (joinRoomMember.uuid === uuid) {
                setTeamName(joinRoomMember.teamName);
              }
              return false;
            }
            return true;
          });
          setRoomMembers([...newJoinRoomInfo, joinRoomMember]);
          break;
        case "joinApexTeam":
          const joinTeamMember = wsData.data.joinTeamMember;
          const newJoinTeamInfo = roomMembers.filter((member) => {
            if (member.uuid === joinTeamMember.uuid) {
              joinTeamMember.name = member.name;
              if (joinTeamMember.uuid === uuid) {
                setTeamName(joinTeamMember.teamName);
              }
              return false;
            }
            return true;
          });
          setRoomMembers([...newJoinTeamInfo, joinTeamMember]);
          break;
        case "leaveApexTeam":
          const leaveTeamMember = wsData.data.leaveTeamMember;
          const newLeaveTeamInfo = roomMembers.filter((member) => {
            if (member.uuid === leaveTeamMember.uuid) {
              leaveTeamMember.name = member.name;
              if (leaveTeamMember.uuid === uuid) {
                setTeamName("");
              }
              return false;
            }
            return true;
          });
          setRoomMembers([...newLeaveTeamInfo, leaveTeamMember]);
          break;
        case "leaveApexRoom":
          const leaveRoomMember = wsData.data.leaveRoomMember;
          const newLeaveRoomInfo = roomMembers.filter((member) => {
            return member.uuid !== leaveRoomMember.uuid;
          });
          setRoomMembers(newLeaveRoomInfo);
          break;
        case "timeout":
          const timeoutMember = wsData.data.timeoutMember;
          const newTimeoutInfo = roomMembers.filter((member) => {
            return member.uuid !== timeoutMember.uuid;
          });
          setRoomMembers(newTimeoutInfo);
          break;
        case "updateRoomInfo":
          const updateNewInfo = {
            ...roomInfo,
            title: wsData.data.title,
            keywords: wsData.data.keywords,
            ownerName: wsData.data.ownerName,
            eventDateTime: wsData.data.eventDateTime,
            description: wsData.data.description,
            limitPlayerNumber: wsData.data.limitPlayerNumber,
            gameSettings: wsData.data.gameSettings,
            publicFlag: wsData.data.publicFlag,
            scheduleDateTime: wsData.data.scheduleDateTime,
            customGameCode: wsData.data.customGameCode,
            map: wsData.data.map,
            type: wsData.data.type,
          };
          setRoomInfo(updateNewInfo);
          break;
      }
    };

    props.webSocket?.addEventListener("message", wsEvent);
    return () => props.webSocket?.removeEventListener("message", wsEvent);
  }, [
    props.webSocket,
    roomInfo,
    roomMembers,
    setRoomInfo,
    setTeamName,
    userName,
    uuid,
  ]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const eventNode = event.target as Node;
      if (modalRef.current && !modalRef.current.contains(eventNode)) {
        setIsShowInfo(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props]);

  return (
    <div className={styles.roomContainer}>
      {(isShowInfo || isShowUpdateRoomModal) && (
        <div className={classnames(styles.modalWrapper)}>
          <div className={classnames(styles.modalMask)} />
          <div className={classnames(styles.modalContainer)} ref={modalRef}>
            {isShowUpdateRoomModal && (
              <CURoomModal
                webSocket={props.webSocket}
                isCreate={false}
                initUpdateRoomInfo={roomInfo}
                initRoomType={roomInfo.type}
                onClose={() => {
                  setIsShowInfo(true);
                  setIsShowUpdateRoomModal(false);
                }}
              />
            )}
            {isShowInfo && (
              <Card
                roomInfo={roomInfo}
                byRoom={true}
                onClose={() => {
                  setIsShowInfo(false);
                  setIsShowDeleteWarn(false);
                  setIsShowUpdateRoomModal(false);
                }}
                onDelete={() => {
                  setIsShowInfo(false);
                  setIsShowUpdateRoomModal(false);
                  setIsShowDeleteWarn(true);
                }}
                onEdit={() => {
                  setIsShowInfo(false);
                  setIsShowDeleteWarn(false);
                  setIsShowUpdateRoomModal(true);
                }}
              />
            )}
          </div>
        </div>
      )}
      <div className={styles.roomMenu}>
        <div className={styles.mainMenu}>
          <span
            className={classnames(styles.lobbyMenu, {
              [styles.lobbyMenuShowMap]: isShowMap,
            })}
            onClick={() => {
              setIsShowMap(false);
            }}
          >
            <span className={styles.menuName}>LOBBY</span>
            {!isShowMap && !isChangeTagSize && (
              <span className={styles.menuInfo}>
                {apexGameInfo[roomInfo.type as GameMode] &&
                  apexGameInfo[roomInfo.type as GameMode].teamNumbers}
                TEAMS
              </span>
            )}
          </span>
          <span
            className={classnames(styles.mapMenu, {
              [styles.mapMenuShowMap]: isShowMap,
              [styles.disabled]: roomInfo.type !== "battleRoyale",
            })}
            onClick={() => {
              if (roomInfo.type !== "battleRoyale") return;
              setIsShowMap(true);
            }}
          >
            <span className={classnames(styles.menuName)}>MAP</span>
            {isShowMap && !isChangeTagSize && (
              <span className={styles.menuInfo}>{mapName}</span>
            )}
          </span>
        </div>
        <div className={styles.subMenu}>
          <div
            className={styles.buttonBackGround}
            onClick={() => {
              setIsShowSchedule(true);
            }}
          >
            <span
              className={classnames(
                "material-symbols-rounded",
                styles.calendar
              )}
            >
              calendar_month
            </span>
          </div>
          <div
            className={styles.buttonBackGround}
            onClick={() => {
              setIsShowCode(!isShowCode);
              setCodeVisibility(false);
            }}
          >
            <img
              className={classnames(styles.roomCode)}
              src={customCodeIcon}
              alt=""
            />
          </div>
          {isShowCode && (
            <span className={styles.balloon}>
              <CustomCodeTextBox
                disabled={!isOwner}
                value={roomInfo.customGameCode}
                onChange={(e) => {
                  setRoomInfo({
                    ...roomInfo,
                    customGameCode: e.currentTarget.value,
                  });
                }}
                placeholder={
                  isOwner
                    ? "参加コードを入力してください"
                    : "参加コードが未入力です"
                }
                type={codeVisibility ? "" : "password"}
                onBlur={(e) => {
                  setIsShowCode(false);
                  updateApexRoom(props.webSocket, {
                    ...(roomInfo as UpdateRoomInfo),
                    customGameCode: e.target.value ? e.target.value : "",
                  });
                }}
              />
              {codeVisibility ? (
                <span
                  className={classnames(
                    "material-symbols-rounded",
                    styles.customCodeButton
                  )}
                  onClick={() => {
                    setCodeVisibility(false);
                  }}
                >
                  visibility
                </span>
              ) : (
                <span
                  className={classnames(
                    "material-symbols-rounded",
                    styles.customCodeButton
                  )}
                  onClick={() => {
                    setCodeVisibility(true);
                  }}
                >
                  visibility_off
                </span>
              )}
              <span
                className={classnames(
                  "material-symbols-rounded",
                  styles.customCodeButton
                )}
                onClick={() => {
                  navigator.clipboard.writeText(roomInfo.customGameCode);
                }}
              >
                content_copy
              </span>
            </span>
          )}
          <div
            className={styles.buttonBackGround}
            onClick={() => {
              setIsShowInfo(true);
            }}
          >
            <span
              className={classnames(
                "material-symbols-rounded",
                styles.roomInfo
              )}
            >
              info
            </span>
          </div>
        </div>
      </div>
      {isShowMap ? (
        <div className={styles.mapContainer}>
          <ApexMap type={roomInfo.type as GameMode} map={roomInfo.map} />
        </div>
      ) : (
        <div className={styles.teamList}>
          <div className={styles.allTeams}>
            <div className={styles.unassigned}>
              <div className={styles.label} />
              <div className={styles.title}>
                <div className={styles.left}>未割り当て</div>
                <div className={styles.right}>
                  <span className={styles.number}>
                    {
                      roomMembers.filter((member) => !isEmpty(member.teamName))
                        .length
                    }
                    / {roomInfo.limitPlayerNumber}
                  </span>
                </div>
              </div>
              <div
                className={styles.members}
                onClick={(e) => {
                  if (e && e.detail === 1) {
                    if (isInTeam) {
                      leaveApexTeam(
                        props.webSocket,
                        uuid,
                        roomID,
                        teamName || ""
                      );
                    }
                  }
                }}
              >
                {roomMembers.map((member, index) => {
                  if (isEmpty(member.teamName)) {
                    return (
                      <span
                        key={"unassigned" + index}
                        className={
                          member.uuid === uuid ? styles.userName : styles.name
                        }
                      >
                        {member?.name}
                      </span>
                    );
                  }
                  return <></>;
                })}
              </div>
            </div>
            <div className={styles.teams}>
              {apexGameInfo[roomInfo.type] &&
                apexGameInfo[roomInfo.type].teamList &&
                apexGameInfo[roomInfo.type].teamList.map((teamItem, index) => {
                  const teamItemName = teamItem.teamNumber;
                  const teamMembers = roomMembers.filter((member) => {
                    return member.teamName === teamItemName;
                  });
                  const borderStyle: React.CSSProperties = {
                    borderColor: teamItem.mainColor,
                  };
                  const labelStyle: React.CSSProperties = {
                    borderTopColor: teamItem.mainColor,
                    borderLeftColor: teamItem.mainColor,
                  };
                  const backGroundStyle: React.CSSProperties = {
                    backgroundColor: teamItem.subColor,
                  };
                  const inTheTeam = isInTeam && teamName === teamItemName;
                  return (
                    <div
                      key={"team" + index}
                      className={
                        inTheTeam
                          ? `${styles.team} ${styles.joined}`
                          : styles.team
                      }
                      style={borderStyle}
                    >
                      <div className={styles.label} style={labelStyle} />
                      <div className={styles.title} style={backGroundStyle}>
                        <div className={styles.left}>{teamItem.teamName}</div>
                        <div className={styles.right}>
                          {(teamMembers.length < 3 || inTheTeam) && (
                            <span
                              className={classnames(
                                "material-symbols-rounded",
                                styles.loginout
                              )}
                              onClick={(e) => {
                                if (e && e.detail === 1) {
                                  joinLeaveTeam(inTheTeam, teamItemName);
                                }
                              }}
                            >
                              {inTheTeam ? "logout" : "login"}
                            </span>
                          )}
                        </div>
                      </div>
                      <div
                        className={styles.members}
                        onClick={(e) => {
                          if (teamMembers.length < 3 || inTheTeam) {
                            if (e && e.detail === 1) {
                              joinLeaveTeam(inTheTeam, teamItemName);
                            }
                          }
                        }}
                      >
                        {teamMembers.map((member, index) => {
                          return (
                            <span
                              key={teamItemName + "Member" + index}
                              className={
                                member.uuid === uuid
                                  ? styles.userName
                                  : styles.name
                              }
                            >
                              {member.name}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
      {isOwner ? (
        <OwnerScheduleModal
          webSocket={props.webSocket}
          roomInfo={roomInfo}
          isShow={isShowSchedule}
          onClose={() => {
            setIsShowSchedule(false);
          }}
          onClick={() => {
            setIsShowInfo(false);
            setIsShowUpdateRoomModal(true);
            setIsShowSchedule(false);
          }}
        />
      ) : (
        <PlayerScheduleModal
          roomInfo={roomInfo}
          isShow={isShowSchedule}
          onClose={() => {
            setIsShowSchedule(false);
          }}
        />
      )}
      {isShowDeleteWarn && (
        <DeleteWarnModal
          isShow={isShowDeleteWarn}
          roomID={roomInfo.id}
          onClose={() => {
            setIsShowInfo(true);
            setIsShowDeleteWarn(false);
            setIsShowUpdateRoomModal(false);
          }}
          type={roomInfo.type}
        />
      )}
    </div>
  );
};

export default CommonRoom;
