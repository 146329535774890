import classnames from "classnames";
import styles from "./style.module.scss";
import BasicButton from "../../Base/Button/BasicButton";
import CalendarTextBox from "../../Base/List/CalendarTextBox";
import apexGameInfo from "src/assets/json/apexGameInfo.json";
import { GameMode, Map } from "src/utils/types/roomTypes";
import InputTextSmall from "../../Base/List/TextBoxSmall";
import { RoomGetResult, useApexRoom } from "src/hooks/useApexRoom";
import { useEffect, useRef } from "react";

interface Props {
  isOpen: boolean;
  type: GameMode;
  onClick: (result: RoomGetResult) => void;
  onClose: () => void;
}

const SearchModal: React.FunctionComponent<Props> = (props) => {
  const initalSearchData = {
    fromDate: 0,
    toDate: 0,
    gameMode: props.type,
    maps: [] as Array<Map>,
    ownerName: "",
  };
  const searchData = useApexRoom((state) => state.searchData);
  const setSearchData = useApexRoom((state) => state.setSearchData);
  const setSearching = useApexRoom((state) => state.setSearching);
  const searchApexRooms = useApexRoom((state) => state.searchApexRooms);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const eventNode = event.target as Node;
      if (modalRef.current && !modalRef.current.contains(eventNode)) {
        props.onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props]);

  if (!props.isOpen) {
    return <></>;
  }

  return (
    <div className={classnames(styles.searchContainer)} ref={modalRef}>
      <span
        className={classnames("material-symbols-rounded", styles.closeButton)}
        onClick={() => {
          props.onClose();
        }}
      >
        close
      </span>
      <div className={styles.searchArea}>
        <div className={styles.contents}>
          <div className={styles.content}>
            <div className={styles.inputName}>
              <div className={styles.name}>開催日時</div>
            </div>
            <div className={styles.inputStyle}>
              <CalendarTextBox
                showClose={true}
                onChange={(e) => {
                  setSearchData({
                    ...searchData,
                    fromDate: e,
                    gameMode: props.type,
                  });
                }}
                value={searchData.fromDate}
              />
              <div className={styles.fromTo}>〜</div>
              <CalendarTextBox
                showClose={true}
                onChange={(e) => {
                  setSearchData({
                    ...searchData,
                    toDate: e,
                    gameMode: props.type,
                  });
                }}
                value={searchData.toDate}
              />
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.inputName}>
              <div className={styles.mapInputName}>マップ</div>
            </div>
            <div className={styles.inputStyle}>
              {Object.entries(apexGameInfo[props.type]?.maps).map(
                ([key, value]) => {
                  if (!value.loadImage) {
                    return <></>;
                  }
                  const selected = searchData.maps.includes(key as Map);
                  return (
                    <div>
                      <img
                        className={classnames(styles.image, {
                          [styles.selectedImage]: selected,
                        })}
                        onClick={() => {
                          if (selected) {
                            setSearchData({
                              ...searchData,
                              maps: searchData.maps.filter(
                                (map) => map !== (key as Map)
                              ),
                              gameMode: props.type,
                            });
                          } else {
                            searchData.maps.push(key as Map);
                            setSearchData({
                              ...searchData,
                              maps: searchData.maps,
                              gameMode: props.type,
                            });
                          }
                        }}
                        src={value.loadImage}
                        alt=""
                      />
                      <div className={styles.mapName}>{key}</div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.inputName} />
            <div className={styles.unSelectInputStyle}>
              <BasicButton
                isDisabled={false}
                visual={"fourth"}
                text={"選択を全て解除"}
                onClick={() => {
                  setSearchData({
                    ...searchData,
                    maps: [],
                    gameMode: props.type,
                  });
                }}
              />
              <div className={styles.inputUnderDescription}>
                ※表示されているマップはゲーム内で選択できない場合があります
              </div>
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.inputName}>
              <div className={styles.name}>オーナーネーム</div>
            </div>
            <div className={styles.inputStyle}>
              <InputTextSmall
                value={searchData.ownerName}
                onChange={(e) => {
                  setSearchData({
                    ...searchData,
                    ownerName: e.target.value,
                    gameMode: props.type,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.bottomDescription}>
        <span>
          ※検索結果に表示されるルームはすべて「公開」状態のルームとなります
        </span>
        <span>※非公開のルームは表示されません</span>
      </div>
      <div className={styles.bottom}>
        <div
          className={styles.clearBotton}
          onClick={() => {
            setSearchData(initalSearchData);
          }}
        >
          条件をクリア
        </div>
        <BasicButton
          isDisabled={false}
          visual={"secondary"}
          text={"絞り込む"}
          onClick={() => {
            setSearching(true);
            searchApexRooms(
              props.type,
              searchData.fromDate,
              searchData.toDate,
              searchData.maps,
              searchData.ownerName
            ).then((result) => {
              setSearching(false);
              props.onClose();
              props.onClick(result);
            });
          }}
        />
      </div>
    </div>
  );
};

export default SearchModal;
