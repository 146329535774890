import GeerSelector from "src/components/GeerSelector";
import { useApexMenu } from "src/hooks/useApexMenu";
import styles from "./style.module.scss";
import { useApexRoom } from "src/hooks/useApexRoom";

const ApexMenu: React.FunctionComponent = () => {
  const gameModes = useApexMenu((state) => state.gameModes);
  const selectGameMode = useApexMenu((state) => state.selectGameMode);
  const searchData = useApexRoom((state) => state.searchData);
  const setSearchData = useApexRoom((state) => state.setSearchData);

  return (
    <div className={styles.apexMenuContainer}>
      <GeerSelector
        gameModes={gameModes}
        onSelect={(mode) => {
          setSearchData({
            ...searchData,
            maps: [],
          });
          selectGameMode(mode);
        }}
      />
    </div>
  );
};

export default ApexMenu;
