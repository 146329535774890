import { create } from "zustand";

interface ErrorState {
  joinRoomError: string;
  setJoinRoomError: (error: string) => void;
}

const useError = create<ErrorState>()((set) => ({
  joinRoomError: "",
  setJoinRoomError: (error: string) => {
    set((state) => {
      return { joinRoomError: error };
    });
  },
}));

export { useError };
